.login-container {
  background: linear-gradient($purple, $dark-blue);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @include flexbox;
  justify-content: center;
  position: relative;
  align-items: center;

  .login-msg{
    text-align: center;
    color: #efefef;
    font-size:1rem;
    line-height: 1.4;
  }
  main {
    height: 100%;
  }
  .logo {
    display: block;
    text-align: center;

    @include media($sm-tablet-max) {
      margin-bottom: 2rem;
    }

    > span {
      font-size: 2.5rem;
      display: block;
      margin-bottom: 1em;
    }
  }
  .logo img.logo-icon {
    width: 10rem;
    margin: 0.1rem 0;
    @include media($sm-tablet) {
      margin: 0 0 2.75rem 0;
      max-height: 125px;
      height: 100%;
      width: auto;
    }

    &[src^="/assets/"] {
      max-width: 140px;
      width:100%;
    }
  }
  h2 {
    margin-top: 40px;
    color: $white;
  }
  h3 {
    padding: 1.25rem 0 0.8rem;
    color: $white;
  }
  .btn-group {
    margin-bottom: 1rem;
  }

  .btn-submit {
    letter-spacing: 2px;
    line-height: 1.25;
    padding-top: 1.25rem;
    padding-bottom: 1.125rem;
    font-family: $semi-font;
    position: relative;
    z-index: 99;
  }

  .disclaimer {
    margin-top: 1rem;
    text-align: center;
    font-size: 12px;
    color: rgba($white, 0.75);
  }
}
.login-background-brand {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: no-repeat 0 0 #000;
  background-size: 100%;
  opacity: 0.2;
  background-size: cover;
}
.login-form {
  @include flexbox;
  z-index: 2;
  padding: 0 !important;
  max-width: 460px;
  width: 100%;
  height: 100%;
  @include media($mobile) {
    height: auto;
  }
  .logo-icon {
    padding: 0 1rem ;
  }
  input {
    border: none;
    background-color: rgba(255, 255, 255, 0.15);
  }

  .form-row {
    position: relative;
    @include media($sm-tablet) {
      margin: 0.5rem 0 1.125rem;
    }

    &--space-between {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include media($sm-tablet) {
        margin-top: -0.5rem;
      }

    }

    input[type='text'],
    input[type='password'],
    input[type='email'] {
      padding-left: 60px;
      color: $white;
      @include placeholder {
        color: rgba(255, 255, 255, 0.75);
      }

      &:focus {
        color: #333;

        & + svg {
          fill: #000;
        }
      }
    }
    svg {
      position: absolute;
      fill: $white;
      opacity: 0.5;
      transition: fill 0.25s ease;
      top: 50%;
      left: 12px;
      width: 38px;
      height: 38px;
      margin-top: -19px;
    }
  }

  label.checkbox {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
    font-size: 13px;

    &:before,
    &:after {
      -webkit-filter: invert(0.9);
      -ms-filter: invert(0.9);
      filter: invert(0.9);
    }
  }

  input.checkbox + label:before {
    border-radius: 2px;
    border: 1px solid rgba($white, 0);
  }
  // input.checkbox:checked + label:before {
  //   border: 1px solid rgba($white, 0.5);
  // }
  label,
  a {
    color: $white;
  }
}

.login-standard {
  padding: 0 7%;
  width: 100%;
  p.ip-warning {
    font-size: 0.75rem;
    margin: 0rem 0 0.75rem;
    color: rgba(255, 255, 255, 0.9);
  }
  @include media($sm-tablet) {
    padding: 0 14.675%;
    p.ip-warning {
      font-size: 14px;
    }
  }
}

a.forgot-link {
  display: inline-block;
  padding: 0.2rem 0;
  vertical-align: middle;
  font-size: 13px;
  font-family: $semi-font;
  font-weight: 600;
  text-decoration: none;
  color: $white;
  opacity: 0.7;
  &:hover {
    color: $white;
    opacity: 1;
  }
}

.form-loader:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: $white;
  z-index: 100;
  transition: opacity 0.3s linear;
  visibility: hidden;
}

.form-loader.form-loading:before {
  visibility: visible;
  opacity: 0.4;
}

.login-form-footer footer {
  text-align: center;
  font-size: 12px;
  color: $white;
  position: fixed;
  bottom: 0;
  max-width: 420px;
  width: 100%;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: flex-end;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  opacity:1;
  height:auto;

  @include media($sm-tablet-max) {
    padding-right: 7%;
    padding-left: 7%;
  }

  &.position--static {
    position: relative;
    max-width: unset;
    padding-bottom: 0;
  }

  a {
    display: inline-block;
    margin-bottom: 0.9rem;
    cursor: pointer;
    font-size: 8.25px;

    @include media($sm-tablet) {
      font-size: 10px;
    }
  }
  .footer-logo {
    a {
      img {
        max-width: 2.5rem;
        width:100%;
        max-height: initial;
      }
    }
    .poweredby {
      margin: 0;
    }

  }
}

.footer-link{
  display:none;
}
.footer-logo > a img{
  display:none;
}


@include media($sm-tablet) {
  .visible-sm {
    display: none;
  }
}

@include media($sm-tablet-max) {
  .visible-lg {
    display: none;
  }

  .form-loader .form-row:last-child {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
}

.supported-browsers {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  > li {
    margin: 0;
    padding: 0 5px;
    > img {
      max-width: 60px;
    }
  }
}

