
    .badge {
      padding: .5rem;

    
    

      font-family: $semi-font;
      font-weight:normal;
    
      margin:.5rem 0;
      border-style:solid;color:#333;
        background-color:$sec-color;
        color:#fff;
      vertical-align:middle;
      line-height:1.1;
      position:relative;
      width:auto;
      border-radius: 0.6rem;
      &:before{font-size:1.1rem;position:absolute;left:1.2rem;top:1.05rem;}

          font-size:72.5%;

          &:before{font-size:.725rem;left:.4rem;top:.35rem;}

    
      &.no-radius {
        border-radius: 0;
      }

      &.badge-primary {
        padding: .3rem .5rem .3rem 1.3rem;
        @include alert-theme-inverted($main-color,$icon-info-circle);
      }
      &.badge-secondary {
        padding: .3rem .5rem .3rem 1.3rem;
        @include alert-theme-inverted($sec-color,$icon-info-circle);
      }
      &.badge-info {
        padding: .3rem .5rem .3rem 1.3rem;
        @include alert-theme($info-color,$icon-info-circle);
      }
      &.badge-error {
        padding: .3rem .5rem .3rem 1.3rem;
        @include alert-theme($error-color,$icon-error);
      }
      &.badge-success {
        padding: .3rem .5rem .3rem 1.3rem;
        @include alert-theme($success-color,$icon-tick-thick);
      }
      &.badge-warning {
        padding: .3rem .5rem .3rem 1.3rem;
        @include alert-theme($warning-color,$icon-danger);
      }
    }