$template-borderColor: #e8e9ea;

.intro-help-text {
  margin-top:-4px;
  font-size: 14px;
  opacity:.4;
}

.responsive__template-form {
  section + section { margin-top: 30px; }



  .heading {
    display: flex;
    padding-bottom: 3px;
    justify-content: space-between;
    color: $black;
    border-bottom: 1px solid #AAB3BE;
    h3 {
      padding: 0;
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 30px;
    }
    > span {
      cursor: pointer;
      svg {
        width: 32px;
        height: 32px;
        fill: $black;
      }
      &.active svg { fill: $main-color; }
    }
  }


  @include media($tablet) {
    .heading{
      flex: 0 0 26px;
      max-width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #bfbfbf;
      font-weight: bold;
      h3{
        transform: rotate(-90deg);
        font-size: 14px;
        padding:1px 0 0 0;
        text-transform: uppercase;
        line-height:1;
      }
    }
    section{
      padding:1px 1px 0 0 !important;
      margin:0 !important;
      display:flex;
      flex-direction: row;
    }

    .questionnaire-ratings{
      flex:0 0 150px;
      max-width: 150px;
      background-color: #eee;
    }
  }



  .diagram {
    padding: 20px 25%;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #DDE0E5;
  }

  .question p:last-child { margin-bottom: 0; }
  .question .question-title { padding-top: 0; }

  .questionnaire {
    padding-top: 20px;
    + .questionnaire { margin-top: 20px; border-top: 1px solid #DDE0E5; }
  }



      .question-title {
        margin: 0;
        padding: 0;
        color: $black;
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
        @include media($tablet) {
          min-height: 70px;
        }
      }

  .questionnaire-group {
    display: flex;
    .question {
      padding-right: 20px;
      flex: 1;

      p {
        margin: 5px 0 0;
        padding: 0;
        color: $black;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 1.1;
      }
    }
  }
  .rating {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 220px;
    width:100%;
    text-align: center;

    @include media($tablet) {
      .st-dropdown-trigger:hover .tooltip .tooltip__content {
        opacity: 1;
        visibility: visible;
      }
    }

    .tooltip{
      position: absolute;
      top:4px;
      &__title{
        display:block;
        text-align: left;
        h3{
          font-size:16px;
          padding:0;
          margin:-8px 0 5px;
        }
        h6{font-size: 14px;}
      }
      
      &__button{
          @include media($tablet) {
            visibility:hidden;
            opacity: .5;
            &:hover{
              opacity: 1;
            }
          }
        }
        fieldRules
      &__close{
        @include media($tablet) {
          display: none;
        }
      }
      &__content{
        padding: 1.2rem 1.2rem 0.6rem;
        margin-top:-5px;
        margin-left: -5px;
        transform: translateX(-89%) scale(1);
        &:before {
          left: auto;
          right: 6.5%;
        }
      }
      @include media($tablet) {
        top: 0;
      }
      right: 0;
    }

    textarea::placeholder {
      color: #999;
      font-style: italic;
    }
    .form__label { margin:3px 0 3px; }
    textarea {
      margin: 0;
      padding: 8px 5px;
      text-align: center;
      background: #FFFFFF;
      border: unset;
      resize: none;
      color: #262626;
      font-size: 14px;
      line-height: 1;
      height: 40px;

      &:disabled {
        background: unset;

        @include media($tablet) {
            background: #ddd;
        }

      }
    }

    template-field.ng-invalid {
      border: 1px solid #ED1E23;
      box-shadow: #ED1E23 0px 0px 0.75rem;
    } 

    .validate {
      display: block;
      width: 130px;
      font-size: 11px;
      left: unset;
      right: 0;
      padding: 10px;
      &:before {
        left: unset;
        right: 12px;
      }
    }
  }


  @include media($tablet) {

    .question-title{
      padding: 4px .8em 4px 1em;
      line-height: 1.2;
      display: flex;
      align-items: center;
      height:64px;
      font-weight: 400;
      font-size:14px;
      background: #dedede;
      width:100%;
      b{
        font-weight: 600;
      }
    }


    .questionnaire{
      .questionnaire-table dt.question-help{
        background: #EfF0F2;
        font-weight: 400;
        padding: 8px 15px;
        min-height:110px;
      }

      margin:0 !important;
      border:none !important;
      padding:0 !important;
      display: flex;
      flex-direction: column;
      flex:1;
      //overflow: hidden;
      .questionnaire-table{
        border-left:1px solid $template-borderColor;
        border-right:1px solid $template-borderColor;
        border-bottom:1px solid $template-borderColor;
      }
      .questionnaire-group{
        border-left:1px solid $template-borderColor;
        border-right:1px solid $template-borderColor;
        border-bottom:1px solid $template-borderColor;
        .question{
          padding:0;
          display: flex;
          align-items: center;
          p {
            margin: 0;
            font-size: 13px;
          }
        }
        textarea{
          height:34px;
          padding:7px 5px;
        }
      
        p{
          padding: 5px 1em;
        }

      }
    }
    .questionnaire-table{
      margin-top:0 !important;
      dt{
        margin-top:0 !important;
      }
    }
  }
  .rating{
    max-width:220px;
    width:60%;
    .form__label{
      display: none;
    }
    textarea{
      border:1px solid transparent;
    }
    &:hover .st-dropdown-trigger:not(.disabled) textarea {
      outline: 0px solid transparent;
      border-color: $template-borderColor;
      background-color: $form-field-background-color;
    }
  }
  .questionnaire-ratings .rating{
    width:auto;
    max-width: initial;
  }

  .questionnaire-table, .questionnaire-ratings {
    margin: 10px 0 0;
    display: flex;
    flex-direction: column;
    flex:1;
    dt, .question {
      padding: 8px 15px;
      background: #EEF0F2;
      color: $black;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 1.1;
      &.question-help{
        background: none;
        padding: 0 0 8px;
        font-weight: 400;
        ul,li{
          padding-bottom:.1rem;
        }
      }
    }
    dd, .rating-input {
      position: relative;
      height:100%;
      .mce-content-body {
        height:100%;
        margin: 0;
        padding: 10px 15px;
        background: #FFFFFF;
        border: unset;
        resize: none;
        color: #262626;
        font-size: 15px;
        letter-spacing: 0.25px;
        line-height: 1.1;
        min-height: 110px;
        &:not([contenteditable]) { background: unset; }
      }
      .validate { display: block; font-size: 11px; padding: 10px; }
      
      template-field { 
        position: relative; 
        display: block; 
        height: 100%;
      }

      .mce-edit-focus ~ .input-counter { display: block; }
      template-field.ng-invalid .mce-content-body {
        border: 1px solid #ED1E23;
        box-shadow: #ED1E23 0px 0px 0.75rem;
      }

      div.mce-tinymce-inline{
        position: absolute;
        bottom: 100%;
        z-index: 1;
        top: unset !important;
        left: unset !important;
        right: 0px;
      }

      template-field.ng-invalid-max-lines {
        .mce-content-body,
        textarea {
          border: 1px solid $warning-color;
          box-shadow: $warning-color 0px 0px 0.75rem;
        }

        + p.validate {
          background-color: $warning-color;
          color: $dark-blue;
          &:before {
            border-bottom-color: $warning-color;
          }
        }
      }

    }
  }
  .questionnaire-ratings{
    margin-top:0;
  }
  
}

