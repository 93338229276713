.popup-holder {
  position: fixed;
  top:0;
  left:0;
  background-color:rgba(0,0,0,.8);
  height:100%;
  z-index: 999999;
  width:100%;
  opacity:0;
  visibility: hidden;
  transition:opacity .3s linear;
}
.popup-close{
    position:absolute;
    right:-1rem;
    top:-1rem;
    width:2rem;
    height:2rem;
    font-size:1.5rem;
    font-weight:bold;
    color:$main-color;
    text-align: center;
    cursor: pointer;
    line-height:1.1;
    border-radius:50%;
    border:.1rem solid $main-color;
    background-color:#fff;
    //box-shadow:0 0 4px rgba(0,0,0,.2);
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width:300px;
  width:95%;
  max-width:800px;
  background-color:#fff;
  padding:3% 4%;
}

/*.open-popup{*/
    .popup-holder{
        visibility:visible;
        opacity:1;
    }
/*}*/

.last-comma-holder:last-child .last-comma{
  display: none;
}