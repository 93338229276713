.view-level-widget {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 10;

  @include media($mobile-menu-break) {
    bottom: 20px;
  }

  &__button {
    width: 80px;
    height: 80px;
    // background: $main-color;
    background: $white;
    color: $grey;
    border: none;
    border-radius: 50%;
    font-size: 8px;
    letter-spacing: 0.5px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &:hover {
      color: $main-color !important;
    }

    > span {
      display: block;
      margin-top: -7px;
      font-weight: bold;
    }

    > svg {
      fill: currentColor !important;
      width: 60px;
      height: 60px;
      margin-top: -10px;
    }

    &--md {
      font-size: 7px;
      width: 56px;
      height: 56px;

      > span {
        margin-top: -5px;
      }

      > svg  {
        width: 42px;
        height: 42px;
      }
    }
  }

  &__options {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -75%;
    left: 50%;
    transform: translateX(-50%) scale(0.75);
    transition: all 0.75s cubic-bezier(.34,-0.02,0,1.31);
    // transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform-origin: center;
    visibility: hidden;
    opacity: 0;
    z-index: -1;

    li {
      margin: 0 0 10px;
      padding: 0;
    }

    // button:not(.selected) {
    //   background: $white;
    // }
  }

  &:hover {
    ul {
      bottom: 100%;
      visibility: visible;
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }
  }
}