@mixin container-padding() {
    width: 100%;
    padding-left: 8vw;
    padding-right: 8vw;
    @include media($sm-mobile-max) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @include media($lg-desktop) {
        width: 1366px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }
}

.container {
    @include container-padding();
}

.grid {
    @include flexbox;
    flex-direction: column;
    width:100%;
    padding:$grid-vert-padding 0;

    &.grid-wrap {
        flex-wrap: wrap;
    }
}

.grid:not([class*='--wrap']) .grid-cell {
    flex: 1;
}

/* Alignment per row */

.grid-top {
    align-items: flex-start;
}

.grid-bottom {
    align-items: flex-end;
}

.grid-center {
  align-items: center;
  height:100%;
  justify-content: center;
}

.grid-justify-between {
    justify-content: space-between;
}


/* Alignment per cell */

.grid-cell-top {
    align-self: flex-start;
}

.grid-cell-bottom {
    align-self: flex-end;
}

.grid-cell-center {
    align-self: center;
}

.form-bordered [class*=grid-]:first-child {
    //margin:0
}


/* Base classes for all media */

.grid-fit,
.grid-cell {
    //@include flexbox;
    @include flex(1)
}

.grid > [class*=grid-]{
    width:100%;
    @include media($grid-breakpoint) {
        padding-left:5%;
        padding-right:5%;
    }
}
.grid.no-gutters > [class*=grid-] {
    padding-left:0;
    padding-right:0;
}
.grid.grid-no-padding{padding-top:0;padding-bottom:0;}


@include media($grid-breakpoint) {
    /* With gutters */
    .grid {
        flex-direction: row;
        margin: -$grid-vert-margin 0 0 0;
        margin-left:-$grid-gutter;
        width:100%+$grid-gutter;
    }
    .grid.no-gutters {
        //margin: -1em 0 0 -1em;
        margin: 0;
    }
    .grid > [class*=grid-] {
        //margin: 1em 0 0 1em;
        margin: $grid-vert-margin 0 0 $grid-gutter;
        padding:0;
    }
    .grid.no-gutters{
        width:100%;
    }
    .grid.no-gutters > [class*=grid-] {
        //margin: -1em 0 0 -1em;
        margin: 0;
    }
    .grid.grid-no-padding{padding-top: 0;padding-bottom: 0;}
    .grid.grid-no-padding > [class*=grid-] {
        //margin: -1em 0 0 -1em;
        padding-top: 0;padding-bottom: 0;
    }

    .grid-100 {
        @include flex(1 1 100%);
    }
    .grid-80 {
        @include flex(1 1 80%);
    }
    .grid-75 {
        @include flex(1 1 75%);
    }
    .grid-70 {
        @include flex(1 1 70%);
    }
    .grid-66 {
        @include flex(1 1 66.66%);
    }
    .grid-60 {
        @include flex(1 1 60%);
    }
    .grid-50 {
        @include flex(1 1 50%);
    }
    .grid-45 {
        @include flex(1 1 45%);
    }
    .grid-40 {
        @include flex(1 1 40%);
    }
    .grid-33 {
        @include flex(1 1 33.33%);
    }
    .grid-30 {
        @include flex(1 1 30%);
    }
    .grid-25 {
        @include flex(1 1 25%);
    }
    .grid-20 {
        @include flex(1 1 20%);
    }
    .grid-10 {
        @include flex(1 1 10%);
    }
    .grid-order-end {
        order:10;
    }
}

.hidden {
    display: none!important;
}


.visible-phone {
    display: inherit!important;
}
.visible-tablet {
    display: none!important;
}
.visible-desktop {
    display: none!important;
}

.hidden-phone {
    display: none!important;
}
.hidden-tablet {
    display: inherit!important;
}
.hidden-desktop {
    display: inherit!important;
}



@include media($tablet){
	.visible-phone {
	    display: none!important;
	}
	.visible-tablet {
	    display: inherit!important;
	}
	.visible-desktop {
	    display: none!important;
	}

	.hidden-phone {
	    display: inherit!important;
	}
	.hidden-tablet {
	    display: none!important;
	}
	.hidden-desktop {
	    display: inherit!important;
	}
}

@include media($desktop){
	.visible-phone {
	    display: none!important;
	}
	.visible-tablet {
	    display: none!important;
	}
	.visible-desktop {
	    display: inherit!important;
	}

	.hidden-phone {
	    display: inherit!important;
	}
	.hidden-tablet {
	    display: inherit!important;
	}
	.hidden-desktop {
	    display: none!important;
	}
}



.visible-print {
    display: none!important
}
@media print {
    .visible-print {
        display: inherit!important
    }

    .hidden-print {
        display: none;
     }
  }


.grid {
    &--wrap {
        flex-wrap: wrap;
        width: unset;
        margin-left: -1rem;
        margin-right: -1rem;

        [class*="grid-"] {
            margin-left: 0;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

}