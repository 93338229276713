$sidebar-levels: 1;

$sibebar-link-color: $grey;
$sidebar-border-color: rgba(255, 255, 255, 0.1);
$sidebar-header-height: $header-height-mobile;
$sidebar-main-size: 72px;
$sidebar-main-height: 54px;
$sidebar-selected-bg: $dark-dark-blue;

sidebar-component {
  height: 100%;
}

%sidebar-icon--default {
  width: 36px;
  height: 36px;
  fill: currentColor;
}

%sidebar-deafult-link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: $sibebar-link-color;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.15;
  cursor: pointer;

  > .svg-icon {
    @extend %sidebar-icon--default;
    margin: 0 6px 0 0;
  }
}

%sidebar-deafult-link--selected {
  background-color: $sidebar-selected-bg;
  border-left: 3px solid $main-color;
  border-bottom: 1px solid $sidebar-border-color;
  color: #fff;
  > .svg-icon {
    fill: $main-color;
    //margin-left: -10px;
  }
  & + ul > li.active {
    a {
      color: $main-color;
    }
  }
}

.sidebar {
  &:before {
    content: '';
    width: $sidebar-main-size;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 1;
    height: 100%;
    border-right: 1px solid $sidebar-border-color;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.4);
  }
  font-family: $content-font;
  p {
    margin: 0;
  }
  background-color: $sidebar-bg;
  .header {
    //background-color: $main-color;
    height: $sidebar-header-height;
    width: 100%;
    top: 0;
    border-bottom: 1px solid $sidebar-border-color;
    z-index: 9;
    position: absolute;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media($mobile-menu-break) {
      padding-left: $sidebar-main-size;
    }
    .sidebar-logo {
      height: 95%;
      width: 7rem;
      max-height: 7rem;
      padding: 0.25rem;

      img {
        height: 100%;
        width: 100%;
      }
    }
    .close-menu-button,
    .cart-button,
    .logout-button,
    .account-button {
      color: $sibebar-link-color;
      width: 55px;
      position: absolute;
      font-size: 18px;
      font-weight: normal;
      right: 0px;
      opacity: 0.6;
      top: 0;
      line-height: $sidebar-header-height;
      display: block;
      border-left: 1px solid rgba(0, 0, 0, 0.4);
      height: 100%;
      vertical-align: middle;
      text-align: center;
      span {
        @include ellipsis(190px);
      }
    }
    .close-menu-button {
      cursor: pointer;
      &:before {
        @include icon($icon-cross);
        display: inline-block;
        margin-top: -3px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        opacity: 1;
      }
      z-index: 1;
    }
    .logout-button {
      right: 50px;
      padding-left: 2px;
      z-index: 1;
      &:before {
        @include icon($icon-unlock);
        font-size: 1.2em;
      }
    }
    .account-button {
      display: inline-block;
      font-family: $content-font;
      text-align: left;
      padding-left: 20px;
      width: 100%;
      color: $sibebar-link-color;
      &:before {
        @include icon($icon-account);
        font-size: 27px;
        margin: 15px 8px 0 0;
        float: left;
      }
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  width: $sidebar-width;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
    display: block;

    &.disabled-item {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  span {
    color: $sibebar-link-color;
    position: relative;
  }

  @include media($lg-tablet) {
    li a:hover {
      // background-color: $sidebar-bg-hover;
      color: $main-color !important;
       .svg-icon {
        fill: $main-color !important;
      }
    }
  }

  @include media($mobile-menu-break-max) {
    display: none !important;
  }
}

$sidebar-list-bg: #f9f9f9;

.sidebar-content,
.sidebar-footer {
  position: absolute;
  top: $sidebar-header-height;
  left: 0;
  height: calc(100% - #{$sidebar-header-height});
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  transition: transform 0.3s linear;
  width: $sidebar-width * $sidebar-levels;
  transform: translateX(0);
  h5 {
    display: none;
    width: 100%;
    padding: 0;
    font-family: $title-font;
    font-weight: $title-font-weight;
    color: $sibebar-link-color;
    a,
    span {
      color: $sibebar-link-color;
      display: block;
      padding: 0.75em 0.4em 0.7em 1.1em;
    }
    a:hover {
      background-color: #000;
    }
    border-bottom: 1px solid $sidebar-border-color;
    //border-top: 1px solid #e0e0e0;
    //line-height: 1.3;
    font-size: 19px;
    margin: 0;
    i {
      font-size: 1em;
      margin-right: 5px;
      line-height: 1.1;
    }
  }
  & > ul.main-links {
    width: $sidebar-main-size;
    padding: 0;
    position: fixed;
    left: 0;
    z-index: 2;
    height: 100%;
    .submenu--templates:not(.selected-item) {
      > a {
        background: $main-color;
        color: #fff;
        &:hover { color: #fff !important; }
        svg {
          fill: currentColor !important;
        }
      }
    }
    & > li.channel-leadership{
      &> a > span .nav-name{
        font-size:.65em;
      }
    }
    & > li.channel-capability{
      &> a > span .nav-name{
        font-size:.65em;
      }
    }
    & > li {
      padding: 0;
      &.selected-item {
        & > a {
          span {
            color: $main-color;
          }
        }
        & > ul > li > a {
          color: $white;
        }
      }
      & > a {
        font-family: $title-font;
        height: $sidebar-main-height;
        padding: 0;
        z-index: 1;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        justify-content: center;

        &.locked {
          pointer-events: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          opacity: 0.375;

          .svg-icon {
            width: 28px;
            height: 28px;
            margin: -5px 0 0;
          }

          span {
            position: relative;
            top: unset;
            left: unset;
            transform: unset;
            margin-top: 5px;
          }
        }

        & > span {
          font-weight: 600;

          .nav-name {
            display: block;
            font-size: .8em;
          }
          .channel-navnumber {
            font-size: 1.25em;
          }
        }
        svg {
          @extend %sidebar-icon--default;
        }
      }
    }
    .selected-item {
      & > a {
        @extend %sidebar-deafult-link--selected;
      }
      ul {
        display: block;
			}
			// .submenu--resources {
			// 	ul {
			// 		li.active {
			// 			a {
			// 				color: $grey;
			// 			}
			// 		}
			// 	}
      // }

      &[class*="submenu--"] {
        > a {
          font-weight: 600;
          color: #fff !important;
          .svg-icon {
            fill: $main-color !important;
          }
        }
      }
    }
    .disabled-item {
      pointer-events: none;
      color: #555;
      .svg-icon {
        fill: #4c4c4c !important;
      }
    }
    .home-link--active {
      background: $main-color;
      a {
        color: #fff;
        &:hover { color: #fff !important; }
        svg {
          fill: currentColor !important;
        }
      }
    }
  }
  & > ul {
    width: $sidebar-width;
    padding: 0 1px 0 $sidebar-main-size;
    & > li {
      width: 100%;
      //background-color: $sidebar-list-bg;
      border-bottom: 1px solid $sidebar-border-color;
      span {
        display: block;
        padding: 0;
        width: 100%;
        margin: 0;
        color: inherit;
        .dropdown {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 18px;
          line-height: 3.1;
          cursor: pointer;
          padding: 0;
          text-align: center;
          border-left: 1px solid #eee;
          vertical-align: middle;
          width: 58px;
          height: 100%;
          display: block;
          text-align: center;
          &:before {
            @include icon($icon-arrow-right);
            color: #aaa;
            vertical-align: middle;
            line-height: 1.7;
            margin-right: 3px;
          }
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
      a,
      li.cat-back {
        @extend %sidebar-deafult-link;
      }
      li.cat-back {
        background-color: #eee;
        font-family: $title-font;
      }
      &.active > ul {
        height: 100%;
        z-index: 9999;
      }
      & > ul {
        width: calc(#{$sidebar-width} - #{$sidebar-main-size});
        display: none;
        left: $sidebar-main-size;
        position: absolute;
        background-color: $sidebar-bg;
        top: 0;
        padding: 0;
        height: 100%;

        .MySPS & {
          display: block;
        }
        & > li > a {
          // font-size: 1rem;
          // padding: 1.2rem 0 1.2rem 1.2rem;
          height: $sidebar-main-height;
          display: flex;
          align-items: center;
          //   &:before{
          //       @include icon;
          //       display: inline-block;
          //       vertical-align: top;
          //       margin:0 .75rem 0 0;
          //       color:#777;
          //   }
        }
        li {
          border-bottom: 1px solid $sidebar-border-color;
        }
        li > ul {
          position: relative;
          left: 0;
          > li { border: none; }
          li:first-child{
            padding-top:8px;
          }
          li:last-child{
            padding-bottom:8px;
          }
          a {
            height: $sidebar-main-height - 18;
            font-size: 16px;
            letter-spacing: 0.25px;
            padding: 0 10px 0 $sidebar-main-size - 22px;
            display: flex;
            align-items: center;
            border-left: 3px solid transparent;
          }
        }
        li.selected-item > ul {
          background: $dark-dark-blue;
        }
        //overflow: hidden;
      }

      &.active {
        > a {
          @extend %sidebar-deafult-link--selected;
        }
      }
    }
  }
}

.sidebar-footer {
  overflow: visible;
  //margin: 0;
  //width: ($sidebar-width)-.2rem;
  //float: none;
  //z-index: 1;
  //padding: .7em 1em 1em 0;
  width: calc(100% - #{$sidebar-main-size} + 1px); //
  z-index: 9;
  position: absolute;
  // padding-bottom: 1rem;
  bottom: 0;
  top: auto;
  height: auto;
  & > ul > li:first-child {
    border-top: 1px solid $sidebar-border-color;
  }
  & > ul > li > a {
    min-height: $sidebar-main-height;
  }
  /*&>ul>li:last-child {
        box-shadow:.2rem .2rem .2rem rgba(0, 0, 0, .8);
      }*/

  margin-left: $sidebar-main-size;
  > ul {
    width: auto;
    padding-left: 0;
  }
}
.sidebar-pusher--minimal + .sidebar {
  .sidebar-footer {
    margin-left: 0;
  }
}


.sub-cat > .sidebar-content {
  //left:-$sidebar-width;
  transform: translateX(-$sidebar-width);
}

.dropmenu-holder__menu {

  .sidebar-footer & {
    display: block;
    top: auto;
    position: absolute;
    left: 100%;
    bottom: 0.325rem;
    height: auto;
    z-index: -10;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      background: inherit;
      bottom: 1rem;
      left: -0.5rem;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
      transform-origin: center;
      transform: rotate(45deg);
    }

    li {
      position: relative;
      z-index: 1;
      background: inherit;

      & + li {
        border-top: 1px solid $sidebar-border-color;
      }

      a {
        font-size: 16px;
        display: flex;
        align-items: center;
        height: 46px;

        svg {
          @extend %iconMenuSize;
        }
        &.link--disabled {
          pointer-events: none;
          color: #555;
          .svg-icon {
            fill: #4c4c4c;
          }
        }

        &.invert {
          background-color: $sidebar-selected-bg;
          color: #fff;
          .svg-icon {
            fill: currentColor !important;
          }
        }
      }
    }
  }

  .dropmenu-open > .dropmenu-holder > &,
  .myaccount.dropmenu-open & {
    left: calc(100% + 1rem);
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}

[sidebar-max-height] {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(#fff, 1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($main-color, 0.75);
    border-radius: 10px;
  }
}

.myaccount > a > img.svg-icon {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;

  @include media($sm-tablet) {
    margin: 0 20px 0 10px !important;
  }

  .sidebar & {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10px;
    margin-top: -15px !important;

    & + .svg-icon {
      opacity: 0;
    }
  }
}


.post-delivery-article-navitem {
  font-size: 0.925em;
}
