$checkbox-tick-icon: $icon-tick-thick;
$checkbox-selected-color: $black;
$radio-selected-color: $black;
$checkbox-size:16px; //Number must be divisable by 4;
$label-spacing:.5rem 0 .3rem 0;
$radio-border: solid 1px #888;

input.radio,
input.checkbox {
  position: absolute;
  left: -9999px;
  height: 0;
  width: 0;
}

label.checkbox,
label.radio {
  margin: $label-spacing;
  padding: 0;
  font-size: $checkbox-size;
  line-height: $checkbox-size;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: ceil($checkbox-size * 1.4);
  padding-right: $checkbox-size;
  // transition: color .2s ease;
  &:before {
    // transition: border-color .2s ease;
  }
  &:hover:before {
    border-color: #000;
  }
}

label.radio:before,
label.checkbox:before {
  content: "";
  display: block;
  border: solid 1px #aaa;
  background-color: $form-field-background-color;
  transition: border-color .1s ease;
  width: $checkbox-size;
  height: $checkbox-size;
  top: 0;
  position: absolute;
  left: 0;
}

label.radio:before {
  border-radius: 50%;
}

label.radio:after {
  content: "";
  display: block;
  border-radius: 50%;
  // transition: transform .1s ease;
  visibility: hidden;
  background: $radio-selected-color;
  width: ceil($checkbox-size/2);
  height: ceil($checkbox-size/2);
  transform: scale(.5);
  position: absolute;
  left: ceil($checkbox-size/4);
  top: ceil($checkbox-size/4);
  margin: 0;
}

label.checkbox:after,
input[type=checkbox]+span:after {
  @include bg-icon;
  content: '';
  // transition: transform .1s ease;
  display: block;
  background: url('#{$svg-path}Icons/Tick Sq Bold.svg') no-repeat center / contain;
  width: 32px;
  height: 32px;
  transform: scale(.01);
  text-align: left;
  position: absolute;
  top: -8px;
  left: -8px;
  filter: invert(69%) sepia(81%) saturate(4951%) hue-rotate(163deg) brightness(99%) contrast(101%);
}

label.checkbox:before {
  border: none;
  background: url('#{$svg-path}Icons/Tick Box Empty Bold.svg') no-repeat center / contain;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -8px;
  left: -8px;
  filter: invert(69%) sepia(81%) saturate(4951%) hue-rotate(163deg) brightness(99%) contrast(101%);
}

$checkbox-size: ceil($checkbox-size*1.2);
label.checkbox.checkbox-lg,
label.radio.radio-lg {
  padding-left: ceil($checkbox-size * 1.4);
  font-size: 17px;
  font-weight: bold;
  margin: .7rem 0.7rem .5rem 0;
  line-height: $checkbox-size;
}

label.checkbox.checkbox-lg:before,
label.radio.radio-lg:before {
  width: $checkbox-size;
  height: $checkbox-size; //border-radius:.1rem;
}

label.radio.radio-lg:after {
  width: ceil($checkbox-size/2);
  height: ceil($checkbox-size/2);
  left: ceil($checkbox-size/4);
  top: ceil($checkbox-size/4);
}

label.checkbox.checkbox-lg:after {
  line-height: $checkbox-size;
  font-size: $checkbox-size;
}

input.radio:checked+.radio-btn,
input.checkbox:checked+.radio-btn {
  opacity: 1;
  &:before {
    transform: scale(1);
    opacity: 1;
    margin: -.2rem .5rem 0 -.3rem;
    display: inline-block;
  }
}

input[type=radio]:checked+.radio-btn.radio-select,
input[type=checkbox]:checked+.radio-btn.radio-select {
  span.text-unchecked {
    display: none;
  }
  span.text-checked {
    display: inline;
  }
}

input[type=radio]+.radio-btn.radio-select,
input[type=checkbox]+.radio-btn.radio-select {
  span.text-checked {
    display: none;
  }
}

input[type=radio]:checked+.radio-btn:hover {}


input[type=radio]:checked+.radio:after,
input[type=checkbox]:checked+.checkbox:after,
label.radio.checked:after,
label.checkbox.checked:after {
  visibility: visible;
  transform: scale(1);
}

input[type=radio]:checked+.radio:before,
input[type=checkbox]:checked+.radio:before {
  border: solid 1px $radio-selected-color;
}

input[type=radio]:checked+.checkbox:before,
input[type=checkbox]:checked+.checkbox:before,
input[type=checkbox]:checked+span:before {
  visibility: visible;
  color: $checkbox-selected-color;
  transform: scale(1);
}

input[type=radio]:checked+.checkbox:after,
input[type=checkbox]:checked+.checkbox:after {
  //background: $main-color;
  //border: solid 1px #aaa;
}

input[type=radio]:disabled+.checkbox,
input[type=checkbox]:disabled+.checkbox {
  cursor: not-allowed;
  opacity: .4;
}

input[type=radio]+label.radio,
input[type=checkbox]+label.checkbox,
input[type=checkbox]+span {
  user-select: none;
  display: inline-block;
}

input[type=radio]+label:hover,
input[type=checkbox]+label:hover {
  // cursor: pointer;
}

input[type=radio]+label .sub,
input[type=checkbox]+label .sub {
  color: #b7b7b7;
  display: block;
  margin-top: -3px;
}

label.radio-btn {
  opacity: .85;
  display: inline-block;
  width: auto;
  &:before {
    @include icon($checkbox-tick-icon);
    transition: all .1s ease;
    margin: -.3rem -.3rem 0 -.3rem;
    transform: scale(.01);
    opacity: 0;
    font-size: 1.2em;
  }
}



.radio-btn.radio-btn--inline {
  width: auto;
  display: inline-block;
}

.radio-btn.radio-btn--inline:after {
  margin-left: 0.75rem;
}

.radio-btn:hover {
  cursor: pointer;
}

.radio-btn:after {
  display: none;
}

.radio-btn+.radio-btn {
  margin-right: .5rem;
}

label.radio.radio--inline {
  width: auto;
  display: inline-block;
}

.reveal-area {
  transition: all .3s ease-out;
  max-height: 600px;
  overflow: hidden;
}

input[type=radio].reveal-checkbox:checked+label+.reveal-area,
input[type=checkbox].reveal-checkbox:checked+label+.reveal-area {
  //max-height:520px;
  &~.btn-group #activate-card-btn {
    display: none;
  }
  &~.btn-group #confirm-email-btn {
    display: inline-block;
  }
}

input[type=radio].reveal-checkbox+label+.reveal-area,
input[type=checkbox].reveal-checkbox+label+.reveal-area {
  &~.btn-group #confirm-email-btn {
    display: none;
  }
  &~.btn-group #activate-card-btn {
    display: inline-block;
  }
}

.toggle-hidden.reveal-area {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  border-bottom: 0;
}

//,input[type=radio].reveal-trigger:not(:checked)+label+.reveal-area,input[type=checkbox].reveal-trigger:not(:checked)+label+.reveal-area
#pay-cc:not(:checked)~.pay-cc {
  display: none;
}

#pay-cc:checked~.pay-cc {
  display: table;
}

#pay-bt:not(:checked)~.pay-bt {
  display: none;
}

#pay-bt:checked~.pay-bt {
  display: table;
}

.flyout__field {
  label.checkbox {
    color: #fff;

    &:before,
    &:after {
      filter: invert(1)
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  input[type="checkbox"]:checked + .checkbox::before {
    color: #fff;
  }
}

.flyout__field.field-invalid {
  label.checkbox {
    &:before {
      filter: invert(13%) sepia(89%) saturate(5552%) hue-rotate(354deg) brightness(101%) contrast(87%);
    }
  }
}

.checkbox-label {
  display: flex;

  .tooltip__button svg {
    width: 32px;
    height: 32px;
    margin: 0 -0.8em;
  }

  .tooltip__content {
    padding: 0.5rem 1rem 0.25rem;
  }
}