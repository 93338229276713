.reporting-table {
  &--group {
    padding: 0 1px;
    flex-basis: 0;
    
    > h5 {
      background: darken(#E1E1E1, 10%);
      font-size: 12px;
      text-align: center;
      padding: 10px;
      margin: 0 1px;
      font-weight: bold;
      height: 34px;
    }
    .template-table {
      margin-top: 0;
      table-layout: fixed;
      text-align: center;
      thead th {
        font-size: 10px;
        width: 90px;
        padding: 7px 10px 5px !important;
        height: 26px;
      }
      tbody tr td:first-child {
        background: #fff;
        font-weight: normal;
      }
      th, td {
        text-align: center;
      }
      td {
        height: 40px;
        padding: 0 !important;
        > span {
          display: block;
          font-size: 75%;
          font-weight: bold;
        }
      }
    }
  }
  
  &--grouping {
    display: flex;
    margin-bottom: 15px;
  }
  
  &--aside {
    flex: 1 0 30%;
    max-width: 30%;
    
    h4 {
      background: darken(#E1E1E1, 15%);
      font-size: 16px;
      margin: 0;
      padding: 10px 15px;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  
  &--shelves {
    overflow-x: auto;
    display: flex;
    flex: 1 1 auto;
  }
  
  &--container {
    flex: 1 1 70%;
    max-width: 70%;
    h4 {
      font-size: 14px;
      font-weight: bold;
      background-color: lighten(#E1E1E1, 1);
      margin: 0 2px;
      height: 30px;
      border-bottom: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
  
  &--labels {
    position: relative;
    background: darken(#E1E1E1, 25%);
    display: flex;
    padding-left: 26px;
    h6 {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 3px;
      text-transform: uppercase;
      
      position: absolute;
      top: 50%;
      left: 0;
      margin-left: -18px;
      
      // transform: ;
      transform: translateY(-50%) rotate(270deg);
      transform-origin: center;
    }
    ul {
      background: #E1E1E1;
      flex-grow: 1;
      margin: 0;
      padding: 0;
      list-style-type: none;
      > li {
        margin: 0;
        padding: 0 0 0 10px;
        font-size: 14px;
        border-bottom: 1px solid #fff;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.reporting-submenus {
  > li {
    > a {
      padding: 12px 10px !important;
      font-size: 18px !important;
      // height: unset !important;
    }
  }
}

.reporting-filters {
  // background: #e4e4e4;
  // border: 1px solid #ddd;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
  display: flex;
  list-style-type: none;
  flex-direction: column;
  max-width: unset;
  min-width: unset;
  width: 200px;
}

.reporting-head {
  display: flex;
  align-items: center;

  [class*="__actions"] {
    flex-grow: 1;
    margin-right: 5px;
  }

  filter-column {
    display: block;
    width: 100%;
    padding: 0.25rem 0 0.5rem;

    .dropmenu-trigger__btn {
      margin: 0;
      font-size: 0.9rem;

      > * {
        padding: 5px;
        display: flex;
        justify-content: space-between;
      }

      svg {
        margin-left: 5px;
        width: 16px !important;
        height: 16px !important;
        transform: translateY(2px);
      }
    }

    .dropmenu-holder {
      top: calc(100% + 0.125rem) !important;
      ul li a {
        padding: 0.6rem;
      }
    }

    .dropmenu-holder__menu {
      max-width: unset;
      min-width: unset;
      width: 200px;
    }
  }

  .radio {
    font-size: 14px;
  }

  .userscope-radios {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 0.6rem;
  }
  .userscope-label {
    margin-top: 0.5rem;
    @include tagFontStyle();
  }
}

.reporting-advance-filters .dropmenu-open > .dropmenu-holder {
  top: 3em;
}

.btn__icon.collapsable-target--close {
  width: 32px;
  height: 32px;
  fill: $danger-color;
  transform: translateX(0.4em);
}

reporting-filter .template-docs__actions {
  .user-dropdown {
    .flyout__field-input-model:placeholder-shown {
      border: 1px solid $main-color;
      box-shadow: 0 0 5px 0 lighten($color: $main-color, $amount: 10);
    }
  }
}
