@mixin wrapper {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  @include media($sm-phablet) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media($phablet) {
    padding-left:2.5%;
    padding-right: 2.5%;
  }
}



@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      height: 100%;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}



@mixin media($query:screen and (min-width: 320)) {
  @media #{$query} {
    @content;
  }
}


.mobile-hide{
  @include media($mobile-menu-break-max) {
      display:none !important;
  }
}

.desktop-hide{
    @include media($mobile-menu-break) {
      display:none !important;
    }
}