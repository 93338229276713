$borderColor: #DDE0E5;
%boxShadow {
  border: 1px solid #F6F7F8;
  box-shadow: 0 2px 6px 0 rgba(56,68,75,0.05);
}

@mixin tagFontStyle {
  display: block;
  color: #AAB3BE;
  font-size: 11px !important;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 1.82px;
  font-style: normal;
  text-transform: uppercase;
}


.articles-page [ui-view="content"] {
  // background: #F5F5F5;
  background: #F6F7F8;

  h3 {
    font-family: $reg-font;
    // text-transform: capitalize;

    @include media($sm-tablet-max) {
      font-size: 20px;
    }
  }
  p {
    line-height: 1.5;
  }
}

.logo-icon-sps {
  display: none;
}
.articles-page {
  &:not(.myTemplates):not(.admin-page):not(.MySPS) {

    .logo-icon-client {
      display: none;
    }
  }
  .logo-icon-sps {
    display: block;
  }
  // .main-header {
  //   background-color: $dark-blue !important;
  // }
  footer {
    display: none;
  }
  .articles-main footer {
    display: block;
    position: relative;
  }
}
@include media($mobile-menu-break) {
  .articles-page {
    .main-header {
      display: none;
    }
    .sidebar .header {
      .close-menu-button,
      .account-button {
        display: none;
      }
    }
    .sidebar .header {
      height: $article-header-height;
    }
    .sidebar-content {
      top: $article-header-height;
      overflow: visible;
    }
  }
}

.articles-main {
  padding-left: 10px;
  padding-right: 10px;
  @include media($lg-mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  overflow-x: hidden;
  @include media($mobile-menu-break) {
    padding-top: 0;
    max-width: $container-maxwidth;
    padding-left: 50px;
    padding-right: 50px;
    overflow: visible;
  }
  @include media($mobile-menu-break-max) {
    body.articles-page & { overflow: unset; }
  }
}

.articles-main.main-full {
  max-width: 100%;
}

.breadcrumb {
  display: none;
  h6 {
    text-transform: uppercase;
  }
}

.article-header {
  left: 0;
  top: 0;
  background: $main-color;

  &__container {
    background-color: $main-color;
    position: relative;
    height: $article-header-height;
    // overflow: hidden;

    .blog-page & {
      background-color: $main-color !important;
    }

    &--has-bg {
      background-color: $dark-blue !important;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $dark-blue;
        z-index: 1;
        opacity: 0.25;
      }
    }

    @include media($mobile-menu-break-max) {
      display: none;
    }

    @include media($phablet-max) {
      height: 80px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @include media($mobile-menu-break) {
    // position: absolute;
    // width: 100%;
    // overflow: hidden;
  }
  @include media($mobile-menu-break-max) {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .articles-main {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;

    > h1:first-child {
      padding-left: 0 !important;
    }
  }
  h1,
  h2,
  h3 {
    color: #fff;
    font-size: 26px;
    @include media($desktop) {
      font-size: 2rem;
    }
    flex: 1;
    padding-left: 2%;

    @include media($tablet-max) {
      max-width: 55%;
      margin-right: auto;
    }

    .MySPS & {
      margin: 0;
      padding-top: 0;
      font-weight: lighter;
      font-family: $light-font;
    }
  }
  h1 {
    padding-top: 0;
    margin-bottom: 0;
  }
  .background-image {
    width: 100%;
    top: 0;
    left: 0;
    transform: scale(1.25);
    height: 100%;
    position: absolute;
    background-image: url('/assets/images/rowing.jpg');
    background-size: cover;
    filter: blur(5px);
    background-position: center;
    z-index: 0;
  }
  .channel-display {
    border-right: 0.1rem solid #fff;
    padding-left: 0;
    display:flex;
    svg{
      width:100%;
    }

    @include media($sm-mobile) {
      padding-left: 1%;
    }

    @include media($sm-tablet) {
      padding-left: 8%;
    }

    @include media($mobile-menu-break) {
      padding-left: 0;
    }
  }
}

article {
  figure {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .article-body {
    padding-top: 30px;
    padding-bottom: 4rem;
    // min-height: 100vh;

    @include media($sm-mobile) {
      padding-left: 3%;
      padding-right: 3%;
    }

    @include media($sm-tablet) {
      padding-left: 8%;
      padding-right: 8%;
    }

    @include media($mobile-menu-break) {
      padding: 4rem 0;
    }
  }
}

.articles-list {
  article {
    width: 100%;
    margin: 3px 0;
  }
  article:hover {
    z-index: 2;
  }
}
article.main-sidebar {
  flex: 1;
}
aside {
  flex: 0 0 15em;
  padding: 20px;
}

.gutter-sizer {
  width: 1%;
}

.resources {
  padding: 0 0 4rem;
  @include media($mobile-menu-break) {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
  }
  @include media($mobile-menu-break-max) {
    margin-top: 2rem;
  }
}
.resource {
  padding: 25px 50px 30px;
  position: relative;
  margin: 0 0 20px;
  @extend %boxShadow;
  background-color: #fff;

  h3 {
    padding-top: 0.325em;
    font-size: 17px !important;
    margin-bottom: 0.5em !important;
    @include media($tablet) {font-size: 22px !important;}
  }

  &--no-box {
    padding: 0;
    box-shadow: none;
    background: none;
    margin: 0 !important;
    border: none;
  }

  &--no-download {
    ul li a:after {
      display: none;
    }
  }

  @include media($mobile-menu-break) {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }

  @include media($lg-phablet-max) {
    padding: 20px 25px 15px;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
  h2 {
    font-size: 24px;
    .articles-page & {
			border-bottom: 0;
			margin-bottom: 0;
		}
  }
  ul {
    @include list-reset;
    padding: 0.5rem 0;
    li {
      position: relative;
      &:not(:empty):before {
        content: '';
        width: 50px;
        height: 50px;
        background-color: #F6F7F8;
        position: absolute;
        top: 0;
        left: 0;
      }
      p {
        margin: 0;
        a {
          text-decoration: none;
        }
      }
      a {
        position: relative;
        padding: 0 40px 0.25rem 0;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;

        i, em {
          margin-left: auto;
          color: $grey;
          font-size: 13px;
          font-style: normal;
          opacity: 0.75;
          transition: cubic-bezier(0.075, 0.82, 0.165, 1) opacity 0.3s;
          // font-weight: normal;
        }

        &:hover {
          color: $main-color;
          span:not([class]):before {
            background: $main-color;
            z-index: 1;
          }

          i, em {
            color: $main-color;
            &:after {
              opacity: 1;
            }
          }

          &:before {
            opacity: 1 !important;
            background-color: #f15900 !important;
            filter: invert(100%) !important;
          }

          &:after {
            filter: invert(45%) sepia(100%) saturate(1740%) hue-rotate(164deg) brightness(100%) contrast(103%);
          }
        }

        span:not([class]) {
          position: relative;
          margin-right: 15px;
          flex-shrink: 0;
          width: 78px;
          height: 50px;
          position: relative;
          overflow: hidden;

          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.25);
          }
          &:after {
            @include bg-icon;
            background: url('#{$svg-path}Icons/Play.svg') no-repeat center;
            background-size: 70px;
            filter: invert(100%) sepia(5%) saturate(705%) hue-rotate(197deg) brightness(118%) contrast(100%);
            z-index: 1;
          }

          img {
            position: absolute;
            top: -10%;
            left: -10%;
            width: 100px !important;
            max-width: 100px;
          }
        }
        &:after {
          @include bg-icon;
          content: '';
          width: 30px;
          height: 30px;
          margin-left: 10px;
          background: url('#{$svg-path}Icons/Download.svg') no-repeat center;
          background-size: 30px;
          flex-shrink: 0;
          opacity: 0.75;
          position: absolute;
          top: calc(50% - 3px);
          right: 0;
          transform: translateY(-50%);
          transition: cubic-bezier(0.075, 0.82, 0.165, 1) opacity 0.3s;
          @include iconFilter();
        }
        &[href*='.doc'],
        &[href*='.docx'],
        &[href*='.pdf'],
        &[href*='.pptx'],
        &[href*='.ppt'],
        &[href*='.jpg'],
        &[href*='.jpeg'],
        &[href*='.svg'],
        &[href*='.png'],
        &[href*='.xls'],
        &[href*='.xlsx'],
        &[href*='fliphtml5'],
        &[href*='.html'] {
          &:before {
            content: '';
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            margin-right: 15px;
            background-size: 42px;
            background-repeat: no-repeat;
            // background-color: #dadada;
            background-position: center;
            @include iconFilter();
          }
        }
        &[href*='.doc'],
        &[href*='.docx'] {
          &:before {
            background-image: url('#{$svg-path}Icons/File Word.svg');
          }
        }
        &[href*='.pdf'] {
          &:before {
            background-image: url('#{$svg-path}Icons/File PDF.svg');
          }
        }
        &[href*='.ppt'],
        &[href*='.pptx'],
        &[href*='fliphtml5'],
        &[href*='.html'] {
          &:before {
            background-image: url('#{$svg-path}Icons/File Powerpoint.svg');
          }
        }
        &[href*='.jpg'],
        &[href*='.jpeg'],
        &[href*='.png'],
        &[href*='.svg'] {
          &:before {
            background-image: url('#{$svg-path}Icons/File Image.svg');
          }
        }
        &[href*='.xls'],
        &[href*='.xlsx'] {
          &:before {
            background-image: url('#{$svg-path}Icons/File Excel.svg');
          }
        }
      }
    }

    + ul {
      padding-top: 0;
      margin-top: -0.5rem;
    }
  }
}

.articles-page pre {
	background: #cecece;
	padding: 1rem;
	margin-bottom: 2.5rem;
	margin-top: 0.5rem;
	font-size: 12px;
  white-space: pre-wrap;
  user-select: text;
}

// Content Component
.row-block {
  display: flex;

  @include media($lg-phablet-max) {
    flex-wrap: wrap;
  }

  &:not(.row-block--box) {
    .col-block {
      width: 100%;
    }
  }

  h4 {
    line-height: 1.425;
    position: relative;

    em { @include tagFontStyle(); }
  }
}
@include media($lg-phablet) {
	.row-block {
		margin: 0 -1rem;
	}

	.col-block {
		flex: 1 1 0;
    padding: 0 1rem;

    &--360 {
      flex: 1 0 360px;
      max-width: 360px;
    }

    &.is-two-thirds {
      flex: 1 1 66.6666%;
      max-width: 66.6666%;
    }

    &.is-one-third {
      flex: 1 1 33.3333%;
      max-width: 33.3333%;
    }

    &.is-three-fifths {
      flex: 1 1 60%;
      max-width: 60%;
    }

    &.is-two-fifths {
      flex: 1 1 40%;
      max-width: 40%;
    }
	}
}

[class^='resource-item--'] {
  display: block;
	position: relative;
  padding-left: 3rem;
  padding-right: 5.5rem;
	margin-bottom: 1.5rem;
  @include media($lg-phablet) {
    padding-left: 3.5rem;
    padding-right: 6rem;
  }

	&:before {
    @include bg-icon;
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 42px;
		height: 42px;
    @include iconFilter();
		background-position: center;
		background-size: contain;
    -webkit-background-size: contain;
		background-repeat: no-repeat;
    @include media($lg-phablet) {
      width: 48px;
      height: 48px;
    }
	}

  &:after {
    content: attr(data-size);
		display: block;
    position: absolute;
    top: 0;
    right: 1em;
    background-image: url("#{$svg-path}Icons/Download.svg");
    background-position: center right;
		background-size: contain;
    -webkit-background-size: contain;
		background-repeat: no-repeat;
    @include iconFilter();
    font-size: 11px;
    text-transform: uppercase;
    padding: 0.5em 3.25em 0.5em 0;
    margin-top: 0.8em;
    letter-spacing: 0.5px;
    @include media($lg-phablet) {
      margin-top: 0.5em;
      font-size: 13px;
    }
  }

	&[class$='powerpoint']:before {
		background-image: url('#{$svg-path}Icons/File Powerpoint.svg')
	}
	&[class$='excel']:before {
		background-image: url('#{$svg-path}Icons/File Excel.svg')
	}
	&[class$='word']:before {
		background-image: url('#{$svg-path}Icons/File Word.svg')
	}
	&[class$='pdf']:before {
		background-image: url('#{$svg-path}Icons/File PDF.svg')
	}
	&[class$='image']:before {
		background-image: url('#{$svg-path}Icons/File Image.svg')
	}
	&[class$='video']:before {
		background-image: url('#{$svg-path}Icons/File Video.svg')
	}
	&[class$='audio']:before {
		background-image: url('#{$svg-path}Icons/File Audio.svg')
	}
}

.inline-resource {
	h4 {
		padding: 0.6em 0 0 !important;
		margin-bottom: 0.325em !important;
		font-weight: 600;
		color: #333;
    line-height: 1.5;
    font-size: 15px !important;

    @include media($lg-phablet) {
      font-size: 17px !important;
      padding: 0.75em 0 0 !important;
    }
	}
	p {
		margin-top: 0;
		margin-bottom: 10px;
    font-size: 13px;
    @include media($lg-phablet) {
      font-size: 15px;
    }
	}
}

blockquote {
  position: relative;
	text-align: center;
	font-size: 1.125rem;
	color: #333;
	font-weight: 600;
  line-height: 1.6;
  margin-right: auto;
  margin-left: auto;
  max-width: 280px;
  padding: 1.25rem;
  box-shadow: 0 8px 24px 0 rgba(56,68,75,0.05);
  background-color: $white;
  margin-bottom: 5rem;

  strong { font-weight: 600; }

  @include media($mobile-menu-break) {
    max-width: 414px;
    padding: 1.5rem;
  }

	.from {
		position: absolute;
		display: block;
		font-size: 15px;
    color: $main-color;
    font-weight: normal;
    line-height: 1.5;
    top: 100%;
    left: 0;
    width: 100%;

    p { margin: 0; }

		&:before {
      content: '';
      display: block;
			width: 0;
      height: 0;
      border-top: 1.75rem solid white;
      border-left: 1.75rem solid transparent;
      margin: 0 auto 0.75rem;
      transform: translateX(-0.7rem);
      // filter: drop-shadow(0 8px 24px 0 rgba(56,68,75,0.05));
		}
	}
}
@mixin titleCardIcon {
  @include iconFilter();
  width: 48px;
  height: 48px;
  position: absolute;
  right: calc(100% + 0.25rem);
  top: -0.0125rem;
  flex-shrink: 0;
  @include media($tablet) {
    width: 52px;
    height: 52px;
  }
}

.row-block--box,
.row-block--overview {
  padding: 2em 20px;
  margin: 0;
  background: #EEF0F2;
  @extend %boxShadow;
  border-color: #e6e6e6;
  flex-wrap: wrap;

  &:not(.row-block--image) {
    > div:last-of-type:not(.col-block--image) {
      flex: 1 1 0;
      max-width: none;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    padding-top: 0.25rem;
    svg {
      width: 80px;
      height: 80px;
      fill: $main-color;
      margin-right: 20px;
      margin-left: -20px;
      margin-top: -10px;
      flex-shrink: 0;
      @include media ($sm-tablet-max) {
        height: 60px;
        width: 60px;
        margin-left: -5px;
        margin-right: 20px;
      }
    }
  }

  h4 {
    display: flex;
    margin-bottom: 15px;
    padding-top: 0 !important;
    font-size: 17px !important;
    font-family: $reg-font;
    @include media($sm-tablet) { font-size: 20px !important }
    svg {
      @include titleCardIcon();
    }
  }


  @include media($lg-phablet-max) {
    padding: 25px 40px;
  }

  @include media($lg-phablet) {
    &.row-block--gutter,
    &.row-block--overview {
      padding-left: 3.5rem;
      padding-right: 3.5rem;

      .col-block {
        padding-left: 1.75rem;
        padding-right: 1.5rem;
      }
    }
  }

  table {
    margin-top: 20px;
    table-layout: unset;
    background: none;
    tr {
      background: inherit;
      border: none;
      th {
        font-weight: bold;
        padding-left: 0;
      }
      td, th {
        padding-top: 6px;
        padding-bottom: 6px;
        border: inherit;
        background: inherit;
        font-family: $reg-font;

        @include media($lg-phablet) {
          font-size: 17px;
        }
      }
    }
  }
}
.col-block--icon {
  text-align: center;

  @include media($lg-phablet) {
    margin-top: 5px;
    flex: 1 0 4rem;
    max-width: 4rem;
  }

  figure {
    display: inline-block;
    width: auto;
    text-align: center;
    margin: auto;
    overflow: visible;

    svg {
      @extend %theme-icon-style;
      // margin-top: -12px;
      // width: 80px !important;
      // height: 80px !important;

      @include media($lg-phablet-max) {
        width: 42px !important;
        height: 42px !important;
        margin-top: -7px !important;
        margin-left: -30px;
        margin-right: 10px;
      }
    }
  }
}

.row-block--image-cover {
  margin: 0;
  padding: 0;
  background: #fff;
  align-items: center;

  .col-block {
    position: relative;
    padding: 20px 30px;
    overflow: hidden;

    & + [class*="--image"] {
      padding-top: 0;

      @include media($lg-phablet-max) {
        &:last-child {
          padding-bottom: 40px;
        }
      }
    }

    @include media($sm-tablet) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    &:not([class*="--image"]) {
      @include media($tablet) {
        padding: 30px 50px;
      }
  
      @include media($lg-tablet) {
        padding: 50px 80px;
      }
    }

  }

  .col-block--image {
    @include media($sm-tablet) {
      padding: 0;
    }
    @include media($sm-tablet-max) {
      padding: 0 !important;
      order: -1;
    }
  }
}

.articles-page figure {
  max-height: unset;
}

.articles-page .link {
  display: inline-flex;
  text-decoration: underline;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 0.3s;
  &:hover { color: $main-color; }
  &:hover:after { @include iconFilter('blue') }

  &:after {
    content: '';
    display: block;
    margin-left: 0.25em;
    width: 24px;
    background: url('#{$svg-path}Icons/Arrow Right3.svg') no-repeat center;
    background-size: contain;
    -webkit-background-size: contain;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 0.3s;
    @include iconFilter('base-font-color');
  }
}

.row-block--equal-title {

  @include media($tablet-max) {
    flex-wrap: wrap;
  }

  .col-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media($tablet-max) {
      flex: 1 0 100%;
      max-width: 100%;
    }

    h3 {
      line-height: 1.4;
      font-size: 17px !important;
      @include media($tablet) { font-size: 20px !important; }
      @include media($tablet-max) {
        margin-bottom: 0;
        padding-top: 10px;
      }

      // @include media($lg-tablet-max) {
      //   height: auto !important;
      // }
    }

    p {
      flex-grow: 1;
      // font-size: 0.875rem;
    }
  }
}

.row-block--icon-thumb {
  margin-left: 0;
  margin-right: 0;
  display: flex;

  @include media($tablet) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @include media($tablet-max) {
    flex-wrap: wrap;
    margin-left: -1em;
  }

  > .col-block {
    position: relative;

    svg { @include iconFilter(); width: 32px; height: 32px; }

    h4 {
      margin-top: 4px;
      margin-bottom: 0;
      padding-top: 5px;
      font-weight: 600;
      color: #333;
    }
    p {
      margin-top: 2px;
      margin-bottom: 0;
    }

    @include media($tablet-max) {
      flex: 1 1 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      padding-top: 1em;
      padding-left: 1em;
      padding-right: 1em;
      margin-top: 0.75em;
      margin-bottom: 0.75em;
      &:nth-child(even) {
        border-left: 2px solid #DDE0E5;
      }

      h4 {
        position: absolute;
        top: 0;
        left: calc(1.5em + 5px);
        margin: 0;
        @include tagFontStyle;
      }

      p { font-weight: 600; font-size: 13px !important; margin-left: 5px; }
    }

    @include media($tablet) {
      & + .col-block {
        border-left: 2px solid #DDE0E5;
      }

      svg {
        position: absolute;
        top: -8px;
        left: 20px;
        width: 48px;
        height: 48px;
      }

      h4, p {
        margin-left: 45px;
        font-size: 17px !important;
      }
    }
  }
}

.articles-page .next-link {
  display: block;
  margin: 2.5rem auto 0;
  text-align: center;
  flex: 1 1 100%;
  max-width: 100%;

  a {
    display: inline-block;
    border: 1px solid $main-color;
    background: $main-color;
    padding: 15px 40px;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 2px;
    text-decoration: none;
    word-spacing: nowrap;
    white-space: nowrap;
    box-shadow: 0 8px 20px 0 rgba(30,50,62,0.2);

    @include media($lg-phablet-max) {
      font-size: 9px;
      padding: 11px 30px;
    }

    svg {
      fill: currentColor;
      width: 1.5em;
      height: 1.5em;
      margin: -2px 0 0 5px;
    }

    &:hover {
      background: $main-color;
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  }
}

.row-block .next-link {
  margin-bottom: 10px;
  margin-top: 20px;
}

.row-block--image {
  padding-left: 2em;
  padding-right: 2em;
  line-height: 1.5;
  align-items: center;
  background: #fff;
  flex-wrap: nowrap;

  @include media($tablet-max) { display: block; }
  figure {
    a {
      display: block;

      img {
        width: 100%;
      }
    }
  }
  @include media($tablet) {
    [class*='--image'] {
      flex: 1 1 50%;
      max-width: 50%;

      &:last-child {
        padding-right: 2.5rem;
      }
      &:first-child {
        padding-left: 2.5rem;
      }
    }
  }
}

.list-check {
  list-style-type: none;
  margin: 0;
  
  @include media($sm-tablet) {
    display: flex;
    flex-wrap: wrap;
  }
  
  > li {
    position: relative;
    margin: 10px 0 0 0;
    padding: 2.5px 0 5px 40px;
    @include media($sm-tablet) {
      padding: 4px 45px 5px;
    }
    
    &:before {
      @include bg-icon;
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 0;
      @include iconSize();
      background: url('#{$svg-path}Icons/Tick Circle.svg') no-repeat top left;
      background-size: contain;
      -webkit-background-size: contain;
      background-repeat: no-repeat;
      @include iconFilter();
    }
    @include media($sm-tablet) {
      flex: 0 1 50%;
    }
  }
}

.row-block--2column {
  .video-content {
    font-size: 0.45em;
  }


}

.article-header-logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  max-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      min-height: 200%;
      background-color: #fff;
      transform: skew(25deg);
      right: -70px;
      top: -50%;
    }
  }

  @include media($mobile-menu-break) {
    max-width: 260px;
  }
  @include media($sm-tablet-max) {
    picture {
      text-align: center;
      padding-left: 25px;
    }
  }
}

.article-header-client-logo {
  position: relative;
  max-width: 42px;
  z-index: 1;
  // width: 100%;
  transform: translateX(60%);

  @include media($mobile-menu-break) {
    max-width: 140px;
    max-height: 90px;
    width: auto;
    transform: translateX(20%);
  }
}

.steps-block,
.article-thumbnails {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  counter-reset: thumbnailCount;
  > li {
    display: flex;
    margin: 0;
    padding: 8px;
    flex: 1 1 50%;
    max-width: 50%;
    counter-increment: thumbnailCount;

    @include media($tablet) {
      flex: 1 1 33.3333%;
      max-width: 33.3333%;
    }
    @include media($desktop) {
      flex: 1 1 25%;
      max-width: 25%;
    }

    &.disabled {
      opacity: 0.4;

      a,
      .thumbnail-footer .icon {
        display: none;
      }
    }

    article {
      position: relative;
      width: 100%;
      flex-grow: 1;
      padding: 20px 30px 50px;
      @extend %boxShadow;
      background: #fff;
      color: $content-font-color;
      font-family: $semi-font;

      h4 {
        position: relative;
        margin: 10px 0;
        padding: 0 0 0 25px;
        font-family: inherit;
        font-size: 17px;

        @include media($lg-phablet) {
          font-size: 20px;
        }

        &:before {
          content: counter(thumbnailCount) ". ";
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      > a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
      }

      > .icon {
        font-weight: bold;
        font-size: 28px;
        color: $main-color;
        text-transform: uppercase;
        font-family: $bold-font;
        min-height: 42px;
        display: flex;
        align-items: center;

        svg {
          width: 42px;
          height: 42px;
          fill: $main-color;
        }

        @include media($lg-phablet) {
          font-size: 40px;
          min-height: 64px;

          svg {
            width: 64px;
            height: 64px;
          }
        }
      }

      table {
        margin: 0 0 0 17px;
        table-layout: unset;
        font-family: inherit;
        @include media($sm-tablet-max) {
          margin: 0;
        }
        tr {
          border: none;
          th {
            font-weight: bold;
          }
          th, td {
            padding: 3px 10px;
            font-size: 11px;
            font-family: inherit;
            border: none;
            vertical-align: top;

            @include media($lg-phablet) {
              font-size: 13px;
            }
          }
        }
      }

      .thumbnail-footer {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;

        .status {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          background: $grey;
          font-weight: bold;
          color: #fff;
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        .icon {
          margin-left: auto;
          svg {
            fill: $borderColor;
            @include iconSize();
          }
        }
      }
    }
  }
}

.row-accordion {
  position: relative;
  margin: 0;
  border-top: 1px solid #DDE0E5;
  border-bottom: 1px solid #DDE0E5;
  background-color: $white;

  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   width: 150vw;
  //   height: 100%;
  //   background: #fff;
  //   border-top: 1px solid $borderColor;
  //   border-bottom: 1px solid $borderColor;
  //   z-index: 0;
  //   transform: translateX(-50vw);
  // }

  .accordion-head {
    position: relative;
    display: flex;
    min-height: 66px;
    align-items: center;
    -webkit-font-smoothing: subpixel-antialiased;
    cursor: pointer;
    z-index: 1;
    font-weight: 600;
    @include media($lg-phablet-max) {
      font-size: 18px;
    }

    .icon-label {
      margin-right: 12px;
      width: 64px;
      height: 64px;
      @include iconFilter();

      @include media($lg-phablet-max) {
        display: none;
      }
    }

    .icon-arrow {
      margin-left: auto;
      @include iconSize();
      @include iconFilter();

      @include media($lg-phablet) {
        margin-top: -2px;
      }
    }

    &.active {
      .icon-arrow {
        transform: rotate(180deg);
      }
    }
  }
  .accordion-body {
    position: relative;
    z-index: 1;
    padding-bottom: 30px;

    h3 {
      font-size: 23px;
      font-family: $reg-font;
      padding-bottom: 0;

      & + h3 {
        padding-top: 0;
      }
    }
  }
}

@include media($sm-tablet-max) {
  .responsive-table {
    display: block;
    tbody, tr, th, td {
      display: inherit;
    }
  }
}

@include media($sm-tablet-max) {
  .row-block--2column {
    flex-wrap: wrap;
    > .col-block {
      width: 100%;
    }
  }
}

.col-block--title-icon {
  @include media($tablet) {
    padding-right: 3rem !important;
    padding-left: 6rem !important;
  }

  h4 {
    display: block;
    padding-top: 0 !important;
    font-size: 17px !important;
    @include media($sm-tablet) { font-size: 20px !important; margin-top: 1em; }
    @include media($sm-tablet-max) { margin-top: 1em; }
    svg { @include titleCardIcon(); }
    em {
      display: block;
      position: relative;
      top: -0.75em;
    }
  }

  @include media($sm-tablet-max) { 
    h4 {
      left: 2.5em;
      width: calc(100% - 2.5em);
    }
  }

  .row-block--image & {
    @include media($sm-tablet) { padding-left: 4rem !important; }

    h4 {
      svg {
        @include titleCardIcon();
      }
    }
  }
}

$process-spacing_m: 48px;
$process-spacing_d: 60px;

.process {
  &-block,
  &-block--faqs {
    margin-top: 0;
    margin-left: 0;
    list-style-type: none;
    padding-bottom: 0;
    &:not([class*="--faqs"]) {
      background-color: $white;

      .process-item__head {
        min-height: 66px;
      }

      .process-item__title:first-child {
        margin-left: 20px;

        @include media($mobile-menu-break) {
          margin-left: 25px;
        }
      }
    }
  }
  &-item {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid $borderColor;

    .process-block--faqs & {
      border: none !important
    }

    h3 {
      margin: 0 !important;
    }

    &:first-child {
      border-top: 1px solid $borderColor;
    }

    &__head {
      cursor: pointer;
    }

    &__icon {
      margin: 0 5px;
      width: $process-spacing_m;
      height: $process-spacing_m;
      @include iconFilter();

      @include media($mobile-menu-break) {
        margin: 0 10px;
        width: $process-spacing_d;
        height: $process-spacing_d;
      }

    }
    &__title {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 600;

      &-icon {
        margin-left: 10px;
        @include iconFilter();
        width: 32px;
        height: 32px;
      }
      @include media($mobile-menu-break) {
        font-size: 17px;

        &-icon {
          margin-left: 15px;
          width: 42px;
          height: 42px;
        }
      }
    }

    &__btn {
      display: block;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex: 0 0 50px;

      .process-block--faqs & {
        background: none;
        border: none;
        width: 44px;
        height: 44px;
        flex: 0 0 44px;

        @include media($tablet) {
          width: 50px;
          height: 50px;
          flex: 0 0 50px;
        }
      }

      @include media($tablet) {
        width: 64px;
        height: 64px;
        flex: 0 0 64px;
      }

      &:hover {
        border-color: #e1e1e1;
      }

      &-icon {
        transform-origin: center;
        @include iconSize();
        @include iconFilter();

        .active > .process-item__head & {
          transform: rotate(180deg);
        }
      }
    }
    &__head {
      display: flex;
      align-items: center;

      @include media($tablet) {
        align-items: center;
      }
      > strong, b {
        font-weight: 600;
        @include media($tablet-max) {
          align-self: center;
          font-size: 15px;
        }
      }
    }
    &__body {
      display: none;
      padding-right: 20px;
      padding-left: 20px;
      
      .process-block--faqs & {
        margin-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
      }

      @include media($mobile-menu-break) {
        padding-left: 3.5em;
        padding-right: 3.5em;
      }

      .active > & {
        display: block;
      }

      .process-block {
        margin: 0;
      }
    }
  }
}

@mixin step-arrow {
  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #fff;
    position: absolute;
    top: 50%;
    right: -10px;
    margin-top: -10px;
    transform: rotate(45deg);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

    @include media($tablet-max) {
      right: 50%;
      top: auto;
      bottom: -8px;
      width: 16px;
      height: 16px;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 25px;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @include media($tablet-max) {
      height: 25px;
      width: 100%;
      top: auto;
      bottom: 0;
    }
  }
}

.steps-block {
  > li {
    article {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    @include media($tablet-max) {
      flex: 1 1 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      article {
        @include step-arrow();
      }
    }
  }
  .step-info {
    padding-left: 25px;
    font-size: 11px;
    @include media($tablet) { font-size: 13px; }
    @include media($tablet-max) { 
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content max-content max-content;
      column-gap: 1em;
      grid-template-areas:
        "description description"
        "label1 label2"
        "content1 content2";
    }
    
    p {
      margin-bottom: 10px;
      &:last-of-type { grid-area: content2; }
    }
    .step-intro {
      grid-area: description;
      margin: 0 0 20px;
      @include media($tablet) {
        margin-bottom: 0;
        padding: 0 0 10px;
        min-height: 70px;
      }
    }
    h6 {
      font-size: 1em;
      font-weight: 600;
      @include media($tablet-max) { @include tagFontStyle(); }
      &:last-of-type { grid-area: label2; }
    }
  }
}

h4 {
  a {
    color: inherit;
  }
}

.note {
  font-size: 13px;
  color: #38444B;
  p {
    margin: 0 0 15px;
    color: inherit
  }

  &--box {
    padding: 30px 30px 25px;
    border: 1px solid #E1E1E1;
    background-color: #EEF0F2;
    box-shadow: 0 2px 6px 0 rgba(56,68,75,0.1);
    color: #38444B;
  }

  &__title {
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    svg {
      margin-top: -10px;
      margin-left: -10px;
      margin-right: 5px;
      width: 38px;
      height: 38px;
      fill: #B1B1B1;
    }
  }

  &__content {
    ul {
      margin: 0 !important;
      padding: 0;
    }
  }
}

ol {
  .articles-page & {
    margin-left: 0;

    li {
      padding-left: 5px;
      padding-bottom: 15px;
    }
  }
}

.articles-main .row-block--overview {

  @include media($tablet-max) {
    .row-title {
      margin-bottom: 0;
      margin-left: -1.325em;

      + [class*="--icon-thumb"] {
        padding-left: 1em;
      }
    }
  }
}

.row-title {
  display: flex;
  margin: 2px 0 1rem;
  svg {
    fill: $main-color;
    width: 48px;
    height: 48px;
    margin-left: -8px;

    @include media($tablet) {
      width: 64px;
      height: 64px
    }
  }
  h3 {
    margin-bottom: 0 !important;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0.25px;
  }
}

.row-readtime {
  display: flex;
  font-size: 1rem;
  align-items: center;
  strong { font-weight: 600; margin: 0 0.75rem 0 0.325rem; }
  svg {
    fill: #AAB3BE;
    @include iconSize();
    margin-left: -4px;
  }
}
.article-body ul[class="ng-scope"],
ul.list-content {
  margin-left: 0 !important;
  > li {
      position: relative;
      display: block;
      margin-left: 0;
      padding-left: 1.875rem;
      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 0.4875rem;
        left: 0.5rem;
        background: $black;
        border-radius: 50%;
      }
  }
}

.row-block--gutter {
  background-color: $white;

  @include media($mobile-menu-break-max) {
    table {
      margin-bottom: 0;
      tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;

        td, th {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}