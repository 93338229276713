/*theme*/
@import "css/theme/variables.scss";
@import "css/theme/typeface.scss";
@import "css/theme/icons.scss";
/*functions*/

@import "css/functions/_general.scss";
@import "css/functions/_animations.scss";
@import "css/functions/_responsive.scss";
@import "css/functions/_mixins.scss";
@import "css/functions/_layout.scss";
@import "css/functions/_resets.scss";
/*base*/

@import "css/base/grid.scss";
@import "css/base/global.scss";
@import "css/base/font.scss";
@import "css/base/helper.scss";
/*modules*/

@import "css/modules/browser-alert.scss";
@import "css/modules/pdf-viewer.scss";
@import "css/modules/forms.scss";
@import "css/modules/radio-checkbox.scss";
@import "css/modules/alerts.scss";
@import "css/modules/buttons.scss";
@import "css/modules/tables.scss";
@import "css/modules/loading.scss";
@import "css/modules/validation.scss";
// @import "css/modules/expand-area.scss";
@import "css/modules/off-canvas-menu.scss";
@import "css/modules/search-bar.scss";
@import "css/modules/image-zoom.scss";
@import "css/modules/mobile-app.scss";
@import "css/modules/grid-table.scss";
@import "css/modules/popup.scss";
@import "css/modules/ng-dialog-overrides.scss";
@import "css/modules/rating.scss";
@import "css/modules/dropmenu.scss";
@import "css/modules/badge.scss";
@import "css/modules/tabs.scss";
@import "css/modules/print.scss";
@import "css/modules/responsive-template.scss";
// @import "css/modules/viewer.scss";
/*layout*/

@import "app/homepage/homepage.scss";
@import "app/header/header.scss";
@import "app/articles/articles.scss";
@import "app/login/login.scss";
@import "app/sidebar/sidebar.scss";
@import "app/detail/detail.scss";
@import "app/myvantaset/myvantaset.scss";
@import "app/reminders/reminders.scss";
@import "app/iconGuide/tests.scss";
@import "app/mobile/footer/footer.scss";
@import "app/mobile/subArticleMenu/subArticleMenu.scss";
@import "app/register/register.style.scss";
@import "app/search/components/search.component.scss";
@import "app/blog/blog.scss";
@import "app/team/team.style.scss";
@import "app/common/modal/modal.style.scss";
@import "app/common/viewLevelWidget/viewLevelWidget.scss";
@import "app/common/suggestions/index.scss";
@import "app/legal/legal.scss";
@import "app/templates/templates.scss";
@import "app/reporting/reporting.scss";
@import "app/admin/index.scss";

/* @import "assets/svg/combined/svg-symbols"; */