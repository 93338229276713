$mainColor: $black;

.blog-list {
  margin: 0;
  padding: 0;
  list-style: none;
  color: $mainColor;

  > li {
    margin: 0;
    padding-bottom: 15px;
    font-size: 20px;

    a {
      display: inline-block;
      font-family: $reg-font;
      border-bottom: 1px solid $mainColor;
      line-height: 0.8;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.pagination-list {
  margin: 50px 0 0;
  padding: 0;
  list-style: none;
  font-size: 20px;
  text-align: center;

  > li {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;

    > span {
      display: block;
      padding: 3px;
      cursor: pointer;

      &:not(.active) {
        opacity: 0.75;
      }

      svg {
        width: 16px;
        height: 16px;

        & + svg {
          margin-left: -16px;
          
        }
      }
      
    }
  }
}