@font-face {
  font-family: "Panton-Light";
  src: url(#{$font-path}ce71b25d-7dec-4db1-8487-806205a046d5.woff2) format("woff2"), url(#{$font-path}86377c61-f730-4059-8764-41ea8a511df9.woff) format("woff");
        font-weight: 300;
        font-style: normal;
}

@font-face {
  font-family: 'Panton-SemiBold';
  src: url(#{$font-path}350E6E_20_0.woff2) format('woff2'), url(#{$font-path}350E6E_20_0.woff) format('woff');
      font-weight: 600;
      font-style: normal;
}

@font-face {
    font-family: 'Panton-Bold';
    src: url('#{$font-path}panton-bold-webfont.woff2') format('woff2'),
         url('#{$font-path}panton-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@mixin font-bold{
    font-family:$bold-font;
    font-weight:bold;
    color:$content-font-color;
}

@mixin font-semi{
    font-family:$semi-font;
    font-weight:bold;
    color:$content-font-color;
}

@mixin font-reg{
    font-family:$content-font;
    color:$content-font-color;
    font-weight:normal;
}