
$error-color: $red;

#{$text-inputs-list} {
  &.parsley-success {
      border: 1px solid $green;
  }
}
#{$text-inputs-list}{
  &.parsley-error {
      border: 1px solid  lighten($error-color, 45%);
  }
}
.parsley-error .checkbox:after{border: 1px solid lighten($error-color, 45%);}
.parsley-error .select-holder{border: 1px solid lighten($error-color, 45%)}

.parsley-errors-list {
    font-size: 0.9em;
    line-height: 0.9em;
    list-style-type: none;
    margin: 2px 0 3px;
    opacity: 0;
    padding: 0;
    transition: all 0.3s ease 0s;
}

.parsley-errors-list.filled {
    opacity: 1;
    margin: 0 0 -4px 0;
    position: relative;
    display: block;
    width:100%;
    background: lighten($red, 52%);
    color: $error-color;
    padding: 6px 2px 0px;
    font-size: 13px;
    text-align: left;
    display: block;
    border-radius: 0 0 4px 4px;
    li {
        display: block;
    }
}
.form-row.parsley-error{margin-bottom:0;}



.parsley-error input[type=radio].radio-btn:required+label,.parsley-error input[type=checkbox].radio-btn:required+label {
    border:3px solid $error-color;
}



p.validate {
    margin:-.6rem auto .1rem 0;
    display: none;
    color: #fff;
    background:$error-color;
    padding: 12px;
    font-size: 13px;
    width:100%;
    text-align: left;
    position: absolute;
    line-height: 1;
    left: 0;
    top: calc(100% + 22px);
    line-height: auto;
    z-index: 1;

    &:before{
        @include triangle(bottom, $error-color, 10px);
        position:absolute;
        top: -8px;
        left: 10px;
    }
    @if $form-inline-form-fields == true {
        @include media($form-column-breakpoint) {
            margin-left:170px;
            margin-right: 20%;
        }

    }else{
        display: block;
        width:100%;
    }
}


.field-invalid {
  textarea,
	input,.select-holder,[contenteditable=true]{
	  border: 1px solid $error-color !important;
	//   background: #fff7f8 !important;
    //   color: #c91032 !important;
      box-shadow: 0 0 5px 0 $red;
	}
	&::-webkit-input-placeholder {
	  color: #ff676b;
	}
	&:-moz-placeholder {
	  /* Firefox 18- */
	  color: #ff676b;
	}
	&::-moz-placeholder {
	  /* Firefox 19+ */
	  color: #ff676b;
	}
	&:-ms-input-placeholder {
	  color: #ff676b;
	}

	p.validate {
	  display: block;
	}
}