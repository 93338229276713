$tip-font: $content-font;
$tip-text-color: #fff;

.dropmenu-trigger {
  position: relative;
  // z-index: 2;
  // display: inline-block;
  // vertical-align: top;
  // transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
  > a {
    color: #fff;
  }

  &__btn {
    cursor: pointer;
  }

  &--xs {
    &.dropmenu-open .dropmenu-holder { top: calc(100% + 15px) }
    .dropmenu-holder {
      ul {
        min-width: 160px;
        li {
          a {
            min-height: 36px;
            padding: 0.5em 1em;
            font-size: 0.9em;
          }
        }
      }
    }
  }

  &--light {
    .dropmenu-holder {
      background: #ebebeb;
      border: 1px solid #ddd;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);

      &:before {
        content: '';
        border-color: transparent;
        display: block;
        width: 20px;
        height: 20px;
        background: #ebebeb;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        transform: rotate(45deg);
        box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
        top: -0.5rem;
      }

      &--top {
        &:before {
          border: none;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.0325);
        }
      }

      ul {
        li {
          &:not(:first-child) { border-top: 1px solid #ddd; }
          a { color: $dark-grey; }
        }
      }
    }
  }
}

.dropmenu-open > .dropmenu-holder {
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  @include media($mobile-menu-break-max) {
    header & {
      right: 0; 
    }
  }
  @include media($mobile-menu-break) {
    top: 4rem;

    &--top {
      top: unset !important;
      bottom: calc(100% + 15px) !important;
    }
  }
}
// account-menu {
//   position: relative;
//   z-index: 3;
// }
.dropmenu-holder {
  transition: all 0.5s ease-in-out 0.5s;
  z-index: 99;
  position: fixed;
  top: 0;
  background: $dropmenu-bg;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 3.5rem;
  right: -19px;
  @include media($sm-tablet) {
    @include media($mobile-menu-break) { right: 0; }

    &:before {
      // transition: all 0.2s linear;
      @include triangle(bottom, $dropmenu-bg, 0.6rem);
      position: absolute;
      right: 12%;
      @include media($mobile-menu-break) {
        right: 17px;
      }
      top: -0.5rem;
      z-index: -1;
    }

    &:after {
      content: '';
      display: block;
      height: 1px;
      height: 30px;
      background: red;
      position: absolute;
      top: 0;
      right: 0;
    }

    &--top {
      top: unset;
      bottom: 100%;

      &:before {
        top: unset !important;
        bottom: -0.5rem;
        transform-origin: center;
        transform: rotate(180deg);
        
        .template-docs__heading & {
          transform: rotate(220deg) !important;
        }
      }
    }

    &--left {
      right: unset !important;
      left: 0;

      &:before {
        right: unset;
        left: 15px;
      }
    }
  }

  @include media($sm-tablet-max) {

    .main-header & {
      position: fixed;
      top: 0;
      right: -100%;
      height: 100vh;
      min-width: 100%;
      opacity: 1;
      visibility: visible;
    }


    .dropmenu-holder__menu li a {

      [class^='icon-'],
      [class*=' icon-'] {
        margin-right: 0.75rem;
      }
    }
  }

  &__menu {
    min-width: 250px;

    .sidebar-footer & {
      background: $dropmenu-bg;
    }

    a {
      &.default {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $grey;
          position: absolute;
          top: 50%;
          right: 22px;
          margin-top: -5px;
        }
      }
      &.selected {
        position: relative;
        color: $white;
        svg.svg-icon { fill: $main-color !important; }

        &:not(.default) {
          &:before {
            content: '';
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            background: url("../assets/svg/Icons/Tick%20Circle%20Sm.svg") no-repeat center/contain;
            filter: invert(71%) sepia(97%) saturate(4538%) hue-rotate(163deg) brightness(99%) contrast(101%);
          }
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }

  ul {
    @include list-reset();
    text-align: left;
    padding: 0;

    li {
      display: block;
      width: 100%;
      font-size: 0.85rem;
      line-height: 1;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      a {
        display: flex !important;
        align-items: center;
        line-height: 1;
        width: 100%;
        padding: 5px 10px 5px 20px;
        color: #eee;
        background-color: rgba(0, 0, 0, 0.01);
        font-size: 16px;
        transition: unset;
        min-height: 46px;

        .svg-icon {
          margin: 0 15px 0 0;
          @extend %iconMenuSize;
          fill: #eee !important;
        }

        &.link--disabled {
          pointer-events: none;
          color: #5e6a74;
          .svg-icon {
            fill: #5e6a74 !important;
          }
        }
      }
      &:first-child {
        border: none;
      }

      &[disabled] {
        pointer-events: none;
        border-top-color: #8f949c !important;

        a {
          background: #92abbd !important;
          color: #777777 !important;
        }
      }
    }

    &.dropmenu-holder__head {
      li {
        border: 0;
      }
    }

    &[class*='menu--xs'] {
      min-width: 160px;
      > li {
        > a {
          padding: 10px .65rem;
          font-size: 12px;
          min-height: unset;

          .svg-icon {
            @extend %iconMenuSize;
          }
        }
      }
    }
  }

  .dropmenu-holder__menu li a {
    &.link--active,
    &:hover {
      color: #fff !important;
      background: $dropmenu-bg-hover !important;
    }
  }

  .dropmenu-holder__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height-mobile;
    margin-bottom: 1.75rem;
    @include media($sm-tablet) {
      margin-bottom: 0;
      border-bottom: 1px solid #333;
      display: none;
    }
    li {
      flex: 0 0 3.575rem;
      margin: 0 0.75em;

      a {
        padding: 0 10px;
        text-align: center;
        img {
          border-radius: 0;
          max-width: 1.25rem;
        }
      }

      &:last-child {
        a {
          img {
            width: 24px;
            height: auto;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.dropmenu-holder--submenu li a {
  padding-left: 3.75rem !important;

  .option-selected {
    margin-left: -62px !important;
  }

  .sidebar-footer & {
    padding-left: 20px !important;
    padding-right: 50px !important;
    .option-selected {
      margin-left: -54px !important;
    }
  }

}

.dropmenu-trigger__close {
  svg { fill: #98A9BC !important; }
}

.st-dropdown-content {
  position: absolute;
  z-index: 1000;
  background: #F6F7F8;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);

  .note-suggestions {
    all: unset;
  }
}

.st-dropdown-trigger { cursor: pointer; }

body > .st-dropdown-content { z-index: 9999; }

.questionnaire-group .rating .st-dropdown-trigger { display: block; }