$cardTransition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
$homeVerticalSpacing: 3.5vh;

.welcome-username {
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: 300;
  font-family: $reg-font;
  padding: 0 0 $homeVerticalSpacing * 2;
  margin-bottom: 0;
  margin-top:.5rem;
}

$card-transition: 0.3s ease;

.card-locked {
  opacity: 0.5;
  .btn {
    visibility: hidden;
  }
}

.card-holder {

  main.homepage:not(.homepage--collapsed) & {
    display: flex;
    justify-content: center;
  }

  .card {
    // margin: 2% 0;
    width: 100%;
    height: 4.5rem;
    text-align: left;
    position: relative;
    color: #fff;
    transition: $cardTransition;

    @include media($mobile-menu-break-max) {
      max-width: 276px;
      height: 55px;
      margin: auto;
    }

    .card-box {
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      background-color: $dark-blue;
      overflow: hidden;

      .channel-background {
        position: absolute;
        top: -15px;
        left: -15px;
        width: calc(100% + 30px);
        height: calc(100% + 30px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateZ(0);
        transition: all $card-transition;

        @include media($mobile-menu-break-max) {
          display: none;
        }

        .homepage--collapsed & { display: none; }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $dark-blue;
          z-index: 0;
          opacity: 0.25;
        }
      }
    }
    p {
      color: #fff;
      font-size: 12px;
      line-height: 1.5;
    }

    .channel-number {
      font-size: 2.2rem;
      display: inline-block;
    }
    &.card-main .channel-number {
      font-size: 2.8rem;
      line-height: 0.8;
    }
    .channel-label {
      display: inline-block;
      // width: 5rem;
      font-family: $reg-font;
      font-size: 9px;
      font-weight: normal;
      @include media($mobile-menu-break) {
        font-size: 10px;
      }
    }
    .channel-icon,
    .icon-lock {
      position: absolute;
      @include centerer-vertical;
      right: 1rem;
      opacity: 0.9;
    }
    main.homepage:not(.homepage--collapsed) & {
      min-height: 17rem;
      text-align: center;
      max-width: 200px;

      .icon-lock {
        @include centerer-horizontal;
        top: auto;
        right: auto;
        bottom: 2rem;
        opacity: 0.9;;
      }
      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
      .channel-number,
      .channel-label {
        display: block;
        transform: translateZ(0);
      }
      .footer-area {

        p {
          margin: 0.875rem 0 1rem;
        }
        .btn {
          margin: 0;
          @include btn-invert(#fff, $dark-dark-blue);
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $reg-font;
          font-weight: 600;
          font-size: 9px;
          padding: 12px 0 12px 15px;
          width: auto;
          flex-grow: 0;
          min-width: 130px;

          &:last-child {
            margin-top: 10px;
          }

          svg {
            width: 20px;
            height: 11px;
            fill: #fff;
          }

          &:hover {
            svg {
              fill: #000;
            }
          }
        }
      }
    }
    &:hover {

      .card-box {
        .channel-background {
          // opacity: 0.5;
          -ms-filter: blur(0.325rem);
          filter: blur(0.325rem);
          // transform: scale(1.125);
        }
      }
    }
    &.card-main:hover {
      a,
      .card-locked {
        &:before {
          background-color: rgba($dark-dark-blue, 1);
        }
      }
    }
  }
}
.home {
  width: 100%;
  background-color: $dark-dark-blue;

  &:not(.profile):not(.changepassword) {
    height: 100%;
  }

  .image-header {
    position: relative;
    width:100%;
    z-index: 1;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
}

.home .content-container {
  width: 100%;
  height: 100%;
  background-color: $dark-dark-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  [ui-view='content'] {
    width: 100%;
    @include media($mobile-menu-break-max) {
      height: 100%;
    }
  }
}

.opt-dashboard .content-container {
  justify-content: unset;

  [ui-view='content'] {
    padding-top: $homeVerticalSpacing;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    template-landing {
      display: flex;
      flex-direction: column;
      flex: 1
    }

    .templates-landing {
      width: 100%;
      flex: 1;
    }
  }
}

.image-header.is-loading {
  height: 100%;
}

/**** vvvv HERE IS THE NEW STUFF vvvv  ****/

.slider-container {
  position: relative;
  background: url(/assets/images/driver.jpg) no-repeat 100% 100%;
  background-size: cover;
  /* you have to add a height here if your container isn't otherwise set
          becuse the absolutely positioned image divs won't calculate the height
          for you */
  height: 300px;
  width: 100%;
  .header {
    height: 60px;
  }
  h4 {
    padding: 10px 10px;
    bottom: 0;
    margin: 0;
    small {
      font-size: 65%;
      font-weight: $content-font-weight;
    }
    opacity: 0;
    width: 100%;
    visibility: hidden;
    transition: $cardTransition;
    background-color: rgba(darken($main-color, 15%), 0.88);
  }
  a {
    color: #fff;
    transition: opacity 0.2s linear;
    &:hover {
      opacity: 0.6;
    }
  }
}

.blog-footer {
  position: fixed;
  bottom: 0;
  z-index: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(#222, 0.92);
  height: 80px;
  .blog-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 1s ease;
  }
  .container {
    max-width: 475px;
    margin: 0 auto;
    position: relative;
    height: 80px;
    a {
      opacity: 0.85;
      transition: opacity 0.2s ease;
      display: block;
    }
    a:after {
      @include icon($icon-forward);
      color: #eee;
      float: right;
      position: absolute;
      z-index: 1;
      margin-top: -19px;
      top: 50%;
      height: 100%;
      right: 20px;
      font-size: 38px;
      opacity: 0.8;
    }
    a:hover {
      opacity: 1;
    }
    a:hover:after {
      opacity: 1;
    }
    h5 {
      color: #aaa;
      padding: 16px 0 4px 0;
      margin: 0;
      text-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }
    p {
      color: #fff;
      padding: 0;
      margin: 0;
      text-shadow: 0 0 10px rgba(0, 0, 0, 1);
      font-weight: 600;
      max-width: 85%;
      line-height: 0.825em;
    }
  }
}

/* Animations */

.ng-fade {
  opacity: 1 !important; //z-index:1;
  visibility: visible !important;
}

footer {
  position: fixed;
  width: 100%;
  bottom: 10px;
  left: 0;
  height: 55px;
  z-index: 0;
  opacity: 0.75;
  text-align: center;
  opacity: 0.25;
  img {
    max-height: 2.2rem;
  }
  font-size: 8.25px;

  @include media($mobile-menu-break) {
    height: 70px;
    font-size: 12px;
  }
}
.home footer {
  opacity: 1;
  bottom: 1%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @include media($mobile-menu-break-max) {
    padding-top: 40px;
    position: relative;
  }

  > a {
    display: inline-block;
    margin-bottom: 0.9rem;
    color: #fff;
    img{
      display: block;
    }
  }


  .footer-logo {
    margin: 0 5rem;
  }
}

.poweredby {
  color: #fff;
  margin: 0.4rem auto 0.9rem auto;
}

.articles-page .poweredby {
  color: $dark-blue;
}

.card-holder .card svg.channel-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
  transition: $cardTransition;
}
.card-holder .card a.--icon-hide svg {
  display: none;
}

@include media($mobile-menu-break-max) {
  main.homepage {
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .home:not(.opt-dashboard) footer {
    margin-top: 35px;
  }
  .welcome-username {
    font-size: 17px;
    padding-bottom: 20px;
  }
}

@include media($mobile-menu-break) {
  main.homepage {
    padding: 100px 0 25%;
  }
}


main.homepage:not(.homepage--collapsed) {
  padding: 0 15px 12.5%;

  .card-holder .card svg.channel-icon:not(.icon-lock) {
    display: none;
  }
}



.homepage {
  .channel-display {

    transition: all 0.25s ease;
    position: relative;
    padding: 0;
    // margin: 0 0 -8px;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

    > span {
      font-size: 1.75rem;
    }
  }

  .card-holder {
    margin: 0;

    @include media($sm-tablet-max) {
      padding: 0 !important;
    }
    .card {
      display: flex;
      padding: 0 8px;
      > * {
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        transition: $cardTransition;
      }
      &:hover {
        z-index: 1;
        .card-box { box-shadow: 0 0 20px #000; }
      }
      .footer-area {
        display: flex;
        flex-direction: column;
        transition: $cardTransition;
        position: relative;
        padding-top: 20px;
        padding-bottom: 15px;
        // max-height: 0;
        visibility: hidden;
        opacity: 0;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: -100%;
        > p {
          width: 100%;
          flex-grow: 1;
          margin-bottom: -25%;
          transition: $cardTransition;
        }

        & + .btn-mysps {
          display: none;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 3.5rem;

          svg {
            @include iconSize();
            fill: #fff;
          }
        }

      }

      .btn-mysps { margin-top: auto; }
    }
  }

  &:not(.homepage--collapsed) {
    .channel-display {
      transform: translateZ(0) translateY(0.8725rem);
      margin: 0 auto;

      .MySPS & { transform: translateZ(0) translateY(0.75rem); }

      .channel-name {
        max-width: 140px;
        max-height: 77px;
      }

      .channel-name.desktop { display: block; }
      .channel-name.mobile { display: none; }
    }

    .card {
      > * {
        padding-top: 50%;
        align-items: center;
      }

      &:hover {
        transform: scale(1.2);
        .footer-area {
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
          margin-bottom: 0;

          > p {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  &.homepage--collapsed {
    .card-holder {
      padding: 0 25%;
    }
    .channel-display {
      .channel-number {
        font-size: 2.4rem !important;
      }

      .channel-name.desktop { display: none; }
      .channel-name.mobile { display: block; }
    }
    .card {
      padding: 3px 0 !important;

      .footer-area {
        display: none;
        & + .btn-mysps {
          display: block;
        }
      }

      &:hover {
        transform: scale(0.9);
      }
    }
  }
}

body.home {
  &:not(.profile):not(.changepassword) {
    .main-header .menu {
      display: none;
    }
  }
}

.channel-display {
  color: #fff;
  display: inline-block;
  width: auto;
  padding-right: 2%;

  .MySPS .homepage.homepage--collapsed & { display: flex; align-items: center; padding-left: 12px; }

  @include media($mobile-menu-break-max) {
    &[data-channel^="f"] {
      .channel-name {
        max-width: 100px;
      }
    }
  }

  .article-header &#channel-intro {
    .channel-name{
      @include media($lg-phablet) {
        max-width: none;
        width:128px;
        height: 57.2px;
      }
    }
}

  .article-header &#channel-capability {
    .channel-name{
      @include media($lg-phablet) {
        max-width: none;
        width:139px;
        height: 57.2px;
      }
    }
}

.article-header &#channel-ptt {
  .channel-name{
    @include media($lg-phablet) {
      max-width: none;
      width:187.8px;
      height: 57.2px;
    }
  }
}

  .article-header &#channel-culture {
      .channel-name{
        @include media($lg-phablet) {
          max-width: none;
          width:139px;
          height: 57.2px;
        }
      }
  }

  .article-header &#channel-strategy {
    .channel-name{
      @include media($lg-phablet) {
        max-width: none;
        width:139px;
        height: 57.2px;
      }
    }
}


.article-header &#channel-leadership {
  .channel-name{
    @include media($lg-phablet) {
      max-width: none;
      width:139px;
      height: 57.2px;
    }
  }
}

  .channel-name {
    fill: #fff;
    max-width: 120px;
    max-height: 26px;

    .MySPS .homepage & {
      width: 32px;
      height: 32px;

      @include media($mobile-menu-break) {
        width: 70px;
        height: 70px;
      }
    }

    .article-header & {
      max-width: 62px;
      max-height: 57px;

      @include media($lg-phablet) {
        max-width: 82px;
        max-height: 77px;

        &--step {
          max-width: 106px;
          max-height: 60px;
        }
      }
    }
  }

  .channel-number {
    padding-top: 20px;
    font-weight: bold;
    font-size: 46px;
    line-height: 0.75;
    font-family: $title-font;

    .MySPS .homepage & {
      padding-top: 0;
      text-transform: none;
      font-size: 17px !important;
      line-height: 1.25;
      font-family: 'Panton-SemiBold';
      letter-spacing: 1px;
      margin-left: 8px;

      @include media($mobile-menu-break) { font-size: 20px !important; margin-left: 0; }
    }

    @include media($mobile-menu-break) { font-size: 50px; }
    @include media($phablet-max) { font-size: 36px; }
  }
  .channel-label {
    width: 7rem;
    line-height: 1.15;
    letter-spacing: 0.05rem;
    font-family: 'Panton-SemiBold';
    font-size: 8px;
    text-transform: uppercase;

    @include media($mobile-menu-break) {
      width: auto;
      font-size: 10px;
    }

    @include media($phablet-max) {
      font-size: 6px;
    }

    .article-header & {
      width: 8em;
    }
  }
}

.card-main {
  // > a[href="/article/19/198"] {
  //   &:before {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     top: -10px;
  //     right: -10px;
  //     bottom: -10px;
  //     left: -10px;
  //     background: url(/assets/images/rowing.jpg);
  //     width: unset;
  //     height: unset;
  //     background-position: center;
  //     background-size: cover;
  //     transition: all $card-transition;
  //   }

  //   &:hover {
  //     &:before {
  //       opacity: 0.5;
  //       -ms-filter: blur(0.325rem);
  //       filter: blur(0.325rem);
  //       transform: scale(1.125);
  //     }
  //   }
  // }


}

.cards-extra-button {
  margin-bottom: -1vh;
  margin-top: $homeVerticalSpacing;
  text-align: center;
  position: relative;
  z-index: 1;
  pointer-events: none;
  a.btn{margin:0;}

  @include media($mobile-menu-break-max) {
    a.btn {
      padding: 12px 15px;
      font-size: 11px;
    }
  }

  > * {
    pointer-events: auto;
  }

  &.disabled > .btn {
    pointer-events: none;
    //background-color: #666;
    color: #666;
    border-color: #666;
    opacity:.75;
  }
}




/* Light Theme */
.light-theme{
  .template-diagram .diagram-btn {
    filter: url(#diagram_shadow_lightest);
  }
  .template-diagram .diagram-btn:hover {
    filter: url(#diagram_shadow_light);
  }
  .main-header,.homepage .card-holder .card > *{
    box-shadow: 0 0 1px rgba(0,0,0,.01);
  }
  .homepage .card-holder .card:hover .card-box {
    box-shadow: 0 0 8px rgba(0,0,0,.4);
  }
}
