[ui-view='submenus'] {
    position: relative;
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1rem;
        height: 100%;
        background: inherit;
        box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.5);
    }
    &:before {left: -1rem;}
    &:after {right: -1rem;}
}
.article-submenu {
    display: flex;
    list-style-type: none;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0 3%;
    overflow: auto;
    border-bottom: 1px solid #DDE0E5;
    background: #fff;

    @include media($sm-tablet) { padding: 0 8%; }
    @include media($sm-mobile-max) { padding: 0 2px; }
    @include media($mobile-menu-break) { display: none; }
}

.article-submenu--menuitem {
    margin: 0;
    padding: 0;
    white-space: nowrap;

    &.active {
        a {
            color: $main-color;
        }
    }

    a {
        display: block;
        padding: 0.8rem;
        font-size: 13px;
        font-weight: 600;
        color: #38444B;
    }
}