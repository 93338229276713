
.usertable-footer {
  margin-top: 10px;
  display: flex;
  align-items: center;

  .controls {
    margin-left: auto;
    .find-user__controls {
      margin-top: 0;
    }
  }
}

admin-users {
  a {
    cursor: pointer;
  }
}

@include media($mobile-menu-break-max) {
  admin-users-table {
    .template-docs {
      overflow: auto hidden;
      table {
        width: 960px !important;
      }
    }
  }
}