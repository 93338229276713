@include media($mobile-menu-break) {
  div[ui-view='mobile-footer'] {
    display: none;
  }
}

.footer-mobile--menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;

  .articles-page & {
    background-color: #2D363C !important;
  }

  .home.MySPS & {
    display: none;
  }
}

.footer-mobile--menuitem {
  display: block;
  margin: 0;
  padding: 0;
  flex: 1 1 100%;
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: .625rem;
    padding: 0.5rem 0.5rem;
    text-align: center;
    min-height: 50px;
    justify-content: center;

    &.active {
      color: $main-color !important;

      svg {
        fill: $main-color !important;
      }
    }

    svg {
      margin-top: -5px;
      margin-bottom: -5px;
      width: 32px;
      height: 32px;
      fill: #fff;
    }
  }

}

@include media($sm-tablet-max) {
  .footer-mobile--menu-sps {
    overflow: auto;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(#fff, 1);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($main-color, 0.75);
      border-radius: 10px;
    }

    .footer-mobile--menuitem {
      a {
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1;
        white-space: nowrap;
      }
    }
  }
}
