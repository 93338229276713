%suggestionSeparator {
  border-top: 1px solid #EBEBEB;
  padding: 0.325em 0.8em;
}

@mixin activeState() {
  background-color: $main-color;
  color: $white;

  .user-option {
    color: #333;
    background-color: $white;
  }
}

.s-suggestions {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  background: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
  max-height: 300px;
  overflow: auto;

  > li {
    display: block;
    padding: 0.325em 0.8em;
    font-size: 0.8em;
    margin: 0;
    cursor: pointer;
    color: inherit;
    background-color: transparent;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    &:hover {
      background-color: $main-color;
      color: $white;
    }

    &:not(:first-child) {
      .user-option {
        // @extend %suggestionSeparator;
      }
    }

    &.active {
      .user-option {
        @include activeState();
      }
    }
  }

  .s-suggestions--inner {
    margin-right: -0.8em;
    margin-bottom: -0.325em;
    border-left: 1px solid $main-color;
    margin-top: 8px;
    flex-direction: column;
  }

  .s-suggestions-space {
    width: 10px;
    border-right: 1px solid $main-color;
  }

  option-user { display: flex; width: 100%; }
  .user-option {
    flex: 1;
    font-size: 13px; @extend %suggestionSeparator;

    &:hover {
      @include activeState();
    }
  }
  .user-option-row { display: flex; }
  .user-option-row.center { align-items: center; }
  .user-option-badge {
    // background: #EBEBEB !important;
    color: #333 !important;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 4px;
    margin-left: auto;
    text-align: right;
  }

  .user-option-name { font-weight: 600; }

  .user-option-function,
  .user-option-team {
    font-size: 10px;
    font-weight: 600;
  }
  .user-option-team {display: block;}

  .user-option-team:before {
    display: inline-block;
    content: '';
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: $main-color;
    margin: 0 3px
  }
  .user-option-company { margin-left: auto; }
}

.user-dropdown {
  .s-suggestions > li {
    padding: 0;

    &:hover {
      color: inherit;
      background-color: transparent;
    }
  }
}