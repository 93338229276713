
  @font-face {
    font-family: "icon";
    src: url(#{$font-path}Flaticon.woff?v#{random(300)}) format("woff");
    font-weight:normal;
    font-style:normal;
  }

	@mixin icon($icon: "\f100") {
		content:$icon;
		font-family: 'icon';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		text-decoration: inherit;
		vertical-align: middle;
		/* Better Font Rendering =========== */
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
	}

	[class^="icon-"], [class*=" icon-"] {
        display: inline-block;
         @include icon;
  }

  .fi:before{
      display: inline-block;
      font-family: "Flaticon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
   }

.svg-icon {
  height: 64px;
  fill: #ff0000;
} 

.icon-rubbish-bin:before {
   content: "\f100";
 }

.icon-unlock:before {
   content: "\f101";
 }

.icon-lock:before {
   content: "\f102";
 }

.icon-download:before {
   content: "\f103";
 }

.icon-file-word:before {
   content: "\f104";
 }

.icon-file-pdf:before {
   content: "\f105";
 }

.icon-tick-circle:before {
   content: "\f106";
 }

.icon-account:before {
   content: "\f107";
 }

.icon-plus-circle:before {
   content: "\f108";
 }

.icon-minus-circle:before {
   content: "\f109";
 }

.icon-warning:before {
   content: "\f10a";
 }

.icon-history:before {
   content: "\f10b";
 }

.icon-settings:before {
   content: "\f10c";
 }

.icon-info-circle:before {
   content: "\f10d";
 }

.icon-info-circle-full:before {
   content: "\f10e";
 }

.icon-notifications-on:before {
   content: "\f10f";
 }

.icon-more:before {
   content: "\f110";
 }

.icon-eye:before {
   content: "\f111";
 }

.icon-suitcase:before {
   content: "\f112";
 }

.icon-notifications:before {
   content: "\f113";
 }

.icon-add-button:before {
   content: "\f114";
 }

.icon-cancel-button:before {
   content: "\f115";
 }

.icon-note:before {
   content: "\f116";
 }

.icon-arrow-right:before {
   content: "\f117";
 }

.icon-arrow-down:before {
   content: "\f118";
 }

.icon-arrow-left:before {
   content: "\f119";
 }

.icon-arrow-up:before {
   content: "\f11a";
 }

.icon-loading:before {
   content: "\f11b";
 }

.icon-newspaper:before {
   content: "\f11c";
 }

.icon-question:before {
   content: "\f11d";
 }

.icon-new-message:before {
   content: "\f11e";
 }

.icon-link:before {
   content: "\f11f";
 }

.icon-blog:before {
   content: "\f120";
 }

.icon-creativity:before {
   content: "\f121";
 }

.icon-password:before {
   content: "\f122";
 }

.icon-target:before {
   content: "\f123";
 }

.icon-link-full:before {
   content: "\f124";
 }

.icon-rating:before {
   content: "\f125";
 }

.icon-forward:before {
   content: "\f126";
 }

.icon-menu-sml:before {
   content: "\f127";
 }

.icon-back:before {
   content: "\f128";
 }

.icon-menu:before {
   content: "\f129";
 }

.icon-error:before {
   content: "\f12a";
 }

.icon-tool:before {
   content: "\f12b";
 }

.icon-avatar:before {
   content: "\f12c";
 }

.icon-refresh-lock:before {
   content: "\f12d";
 }

.icon-list:before {
   content: "\f12e";
 }

.icon-graph:before {
   content: "\f12f";
 }

.icon-plan:before {
   content: "\f130";
 }

.icon-search:before {
   content: "\f131";
 }

.icon-comment:before {
   content: "\f132";
 }

.icon-tick-thick:before {
   content: "\f133";
 }

.icon-danger:before {
   content: "\f134";
 }

.icon-tick:before {
   content: "\f135";
 }

.icon-cross:before {
   content: "\f136";
 }

 $icon-rubbish-bin: "\f100";
 $icon-unlock: "\f101";
 $icon-lock: "\f102";
 $icon-download: "\f103";
 $icon-file-word: "\f104";
 $icon-file-pdf: "\f105";
 $icon-tick-circle: "\f106";
 $icon-account: "\f107";
 $icon-plus-circle: "\f108";
 $icon-minus-circle: "\f109";
 $icon-warning: "\f10a";
 $icon-history: "\f10b";
 $icon-settings: "\f10c";
 $icon-info-circle: "\f10d";
 $icon-info-circle-full: "\f10e";
 $icon-notifications-on: "\f10f";
 $icon-more: "\f110";
 $icon-eye: "\f111";
 $icon-suitcase: "\f112";
 $icon-notifications: "\f113";
 $icon-add-button: "\f114";
 $icon-cancel-button: "\f115";
 $icon-note: "\f116";
 $icon-arrow-right: "\f117";
 $icon-arrow-down: "\f118";
 $icon-arrow-left: "\f119";
 $icon-arrow-up: "\f11a";
 $icon-loading: "\f11b";
 $icon-newspaper: "\f11c";
 $icon-question: "\f11d";
 $icon-new-message: "\f11e";
 $icon-link: "\f11f";
 $icon-blog: "\f120";
 $icon-creativity: "\f121";
 $icon-password: "\f122";
 $icon-target: "\f123";
 $icon-link-full: "\f124";
 $icon-rating: "\f125";
 $icon-forward: "\f126";
 $icon-menu-sml: "\f127";
 $icon-back: "\f128";
 $icon-menu: "\f129";
 $icon-error: "\f12a";
 $icon-tool: "\f12b";
 $icon-avatar: "\f12c";
 $icon-refresh-lock: "\f12d";
 $icon-list: "\f12e";
 $icon-graph: "\f12f";
 $icon-plan: "\f130";
 $icon-search: "\f131";
 $icon-comment: "\f132";
 $icon-tick-thick: "\f133";
 $icon-danger: "\f134";
 $icon-tick: "\f135";
 $icon-cross: "\f136";