/* Variables
================================== */
$bw: 1px; // border width
$dark-grey:rgb(65, 65, 65);
$grey: rgb(191, 191, 191);

$subnav-height-mobile: 52px;
$subnav-height-desktop: 68px;

$icon-size-desktop:44px;
$icon-size-mobile:32px;



@include media($mobile-menu-break) {
  .mysps-container { margin-top: 4rem; }
}

.mysps-container { padding-bottom: 5em; }

.mysps-landing{
  display:flex;
  height:100%;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}

.sub-nav {

  width: 100%;
  background-color: #333;
  opacity: .9;
  position: fixed;
  z-index: 99;
  top: $header-height-mobile;

  @include media($mobile-menu-break) {
    top: $header-height-desktop;
  }

  ul {
    @include list-reset;
    @include container-padding;

    li {
      float: left;
      margin: 0;
      text-align: center;

      a {
        display: block;
        height: $subnav-height-mobile;
        line-height: $subnav-height-mobile;
        width: 100%;
      }

      width:25%;

      h4 {
        display: none;
        padding: 0;
        margin: 0 .5rem;
        color: #ccc;
        font-size: .925rem;
        line-height: $subnav-height-mobile;
        padding: 0;
        font-weight: normal;
      }

      @include media($sm-tablet) {
        h4 {
          display: inline-block;
        }
      }

      @include media($mobile-menu-break) {
        h4 {
          line-height: $subnav-height-desktop;
        }

        a {
          height: $subnav-height-desktop;
          line-height: $subnav-height-desktop;
        }
      }

      i {
        display: inline-block;
        margin: -4px 0 0;
        font-size: 1.15rem;
        @include circle-border(#777, 2px, .5rem);

        &.icon-note {
          font-size: 1rem;
          padding: .55rem;
        }
      }

      &.active,
      &:hover {
        background-color: #000;
      }
    }
  }

  &.sub-nav-alt {

    background-color: #ddd;

    ul li {
      width: 33.33%;
      text-align: center;
      padding: 0;

      a {
        display: table;
        height: 50px;
        text-align: center;
        margin: 0 auto;
      }

      h4 {
        padding: 0;
        margin: 0 .5rem;
        color: #555;
        display: table-cell;
        vertical-align: middle;
        line-height: 1;
      }

      &.active,
      &:hover {
        background-color: #aaa;
      }
    }

    top:$subnav-height-mobile+$header-height-mobile;

    @include media($mobile-menu-break) {
      top: $subnav-height-desktop + $header-height-desktop;

      ul li a {
        height: 60px;
      }
    }
  }

  ~main {
    margin-top: 1rem;
  }
}



/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  h4 {
    font-family: $title-font;
    color:$black;
    font-weight:600;
    line-height:1.1;
    font-size: 1rem;
  }
  h5 {
    font-family: $title-font;
    color:$black;
    font-weight:600;
    line-height:1.05;
    font-size:16px;
  }
}

.Rtable-cell {
  box-sizing: border-box;
  font-size: .9rem;
  position: relative;
  width: 100%; // Default to full width

  [contenteditable],
  &.Rtable-head {
    padding: 0.5rem;

    @include media($mobile) {
      padding: 12px 6px;
    }
    @include media($sm-tablet) {
      padding: 12px 15px;
    }

    min-height: 3.5rem;
    line-height: 1.15;
    background: #fff;
  }

  //overflow: hidden; // Or flex might break
  list-style: none;
  color:#555;

  //border: solid $bw white;
  //background: #eee;
  >h1,
  >h2,
  >h3,
  >h4,
  >h5,
  >h6 {
    margin: 0;
    padding: 0;
  }



  &.Rtableactive {
    position: relative;
    background-color: $grey !important;

    &:after {
      @include triangle(top, $grey, 1rem);
      position: absolute;
      bottom: -1rem;
      margin-left: -1rem;
      left: 50%;
    }
  }

  strong {
    color: #000;
  }
}

.Rtable-container {

  //background-color:#eee !important;
  padding:.3rem 0rem;
}

.Rtable-title {
  padding: 2rem 1rem 1rem;

  h3 {
    margin: 0;
    padding: 1rem 0;
  }
}

.Rtable.RNav {
  background-color: #eee !important;


  h4 {
    font-size: .85rem;
    font-weight: bold;
    color: #fff;
  }

  .Rtable-head.Rtable-cell {

    &>i {
      font-size: 2.5rem;
      color: #777;
      margin: .5rem 0 .5rem;
    }

    &>a {
      i {
        margin: -.4rem .2rem -.3rem 0
      }

      display: block;
      text-align: center;
    }

    opacity:.75;
    cursor:pointer;
    border:none;
    border-right: solid 1px #ccc;

    background-color:#ddd;

    h4 {
      color: #555;
    }

    padding:1.5rem .5rem;

    @include media($phablet-max) {
      h4 {
        display: none;
      }
    }
  }

  .RTable-link {
    opacity: .75;

    &:hover {
      opacity: 1;
    }
  }

  .RTable-link>a {
    width: 100%;
    height: 100%;
  }

  .RTable-multi {
    @include media($sm-tablet-max) {
      display: none;
    }
  }
}

.Rtable-aux {
  //padding:.5rem 0;
  text-align: center;

  .Rtable-cell {
    min-height: auto;
    border: solid 0 white;
    background: #eee;
  }

  a {
    display: inline-block;
    margin: 0 .4rem 0 0;
    color: #aaa;

    i {
      margin: -.2rem 0 0 0
    }
  }
}


@include media($sm-tablet) {

  /* Table column sizing
================================== */
  .Rtable--2cols>.Rtable-cell {
    width: 50%;
  }

  .Rtable--3cols>.Rtable-cell {
    width: 33.33%;
  }

  .Rtable--4cols>.Rtable-cell {
    width: 25%;
  }

  .Rtable--5cols>.Rtable-cell {
    width: 20%;
  }

  .Rtable--6cols>.Rtable-cell {
    width: 16.6%;
  }

}


.Rtable-head {
  position: relative;
  padding: .4rem 0 .4rem;

  h4 i,
  h5 i {
    margin: -.2rem .3rem 0 .1rem;
  }

  h5 {
    padding: 0;
    margin: 0;
  }

  .icon-tick-circle {
    color: $green;
  }

  .edit-button {
    position: absolute;
    right: 0;
    top: 0.875rem;
  }

  .mysps-block-01 & {
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: $content-font-color;
      display: flex;
      align-items: center;
    }
    .Rtable-head__icon {
      margin-right: 5px;
      height: $icon-size-mobile;
      width: $icon-size-mobile;
      @include media($sm-tablet) {
        height: $icon-size-desktop;
        width: $icon-size-desktop;
      }
      fill: $main-color;
    }
  }
}


.RGoals.Rtable {
  margin: 2rem 0;

  .Rtable-cell {
    border: $bw solid #F5F6F5;
  }

  .Rtable-cell.Rtable-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E1E1E1;
    min-height: 38px;

    .tooltip__button {
      margin-top: -8px;
      margin-bottom: -10px;
      margin-right: -10px;
    }

    @include media($sm-tablet-max) {
      &.Rtable-col-head {
        display: none;
      }
    }
  }

  .Rtable-cell.Rtable-head.Rtable-row-head {
    background: #EBEBEB;
    justify-content: flex-start;
    align-items: center;

    svg {
      flex-shrink: 0;
      height: $icon-size-mobile;
      width: $icon-size-mobile;
      @include media($sm-tablet) {
        height: $icon-size-desktop;
        width: $icon-size-desktop;
      }
      margin-left:-6px;
      margin-right: 6px;
      fill: $main-color;
    }

    @include media($sm-tablet-max) {
      border-top:2px solid $grey;
      border-bottom:2px solid $grey;
      width: 100%;
      text-align: center;
      min-height: auto;

    }
  }

  [contenteditable] {
    border: 1px solid #F5F6F5;
    position: relative;
    height: 100%;

    @include media($sm-tablet-max) {
      // padding-top: 2.4rem;
      // min-height: none;

      // &:after {
      //   content: attr(title);
      //   display: inline-block;
      //   height: 1.75rem;
      //   line-height: 2.1;
      //   left: .0;
      //   position: absolute;
      //   background-color: #eee;
      //   top: 0;
      //   width: 100%;
      //   font-size: .85rem;
      //   text-align: left;
      //   text-indent: 15px;
      // }
    }
  }


  /*.Rtable-cell:nth-child(4n+2){
        background:lighten(#ddd,3%);
    }
    .Rtable-cell:nth-child(4n+3){
        background:lighten(#ddd,6%);
    }
    .Rtable-cell:nth-child(4n+4){
        background:lighten(#ddd,9%);
    }*/
}



.btn.btn-invert.reminder {
  //position: absolute;
  float: right;
  transition: opacity .2s linear;
  opacity: .8;
  margin: -.4rem 0 -.4rem 0;
  padding: 0 0.3rem 0 0;
  line-height: 1.9;

  &:hover {
    opacity: 1;
  }

  &.reminder-set:before {
    color: $main-color;
  }

  .badge {
    display: inline-block;
    width: 1.1rem;
    float: left;
    height: 1.1rem;
    margin: .15rem;
    font-size: .7rem;
    //margin:-.6rem .2rem -.4rem -.4rem;
    line-height: 1.7;
    padding: 0;
  }
}

.RStatements {
  position: relative;
  margin: 1rem 0;

  .Rtable-head {
    position: relative;
  }

  //border: solid $bw transparent;
  h5 {
    padding: .6rem 0;
  }

  .btn.btn-invert.reminder {
    margin: -.4rem 0 -.4rem 0;
  }


  .Rtable-group {
    border: none;
    padding: .4rem 2.5rem .3rem 0;
    position: relative;
  }

  .Rtable-cell[contenteditable] {
    //background:lighten(#eee,3%);
    padding: .7rem;
    border: $bw solid #eee;
    background-color: #fff;
  }
}

.Rtable-holder {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 1rem 1.2rem 1rem;
  margin: .5rem 0;
  overflow: hidden;
}

.Rtable-new {
  margin: 1.5rem 0 1.2rem;
  width: auto;
  cursor: pointer;
  font-size: .85rem;

  i {
    font-size: 1.2rem;
    display: inline-block;
    margin: -.3rem .2rem -.1rem 0rem;
  }

  &:hover {
    a {
      color: #444;
    }
  }
}

.Rcell-title[contenteditable] {
  margin: .0rem 0 .2rem;
  font-weight: bold;
  min-height: auto;
}

.Rcell-message {
  margin: -1px 0 .5rem;
  min-height: 3.5rem;
}

.Rcell-delete {
  position: absolute;
  right: -1.5rem;
  transform: rotate(45deg);

  &:before {
    @include icon($icon-plus-circle);
    font-size: 1.1rem;
  }

  margin:0;
  transition:opacity .2s linear;
  opacity:.6;
  cursor:pointer;
  font-size:.8rem;
  display:inline-block;

  &:hover {
    opacity: 1;
  }
}

[contenteditable=true] {
  transition: all .1s linear;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;

  &:not([disabled]) {
    &:hover {
      background-color: #fff !important;
    }

    &:focus {
      background-color: #fff !important;
      //border: solid $bw #ccc;
      opacity: 1 !important;
      border: 1px solid #00afeb !important;
      box-shadow: 0 0 1.125rem rgba(#00afeb, 0.5);
      position: relative;
      z-index: 9;
    }
  }
}


[contenteditable=true]:not(.ng-not-empty):before {
  pointer-events: none;
  content: attr(placeholder);
  user-select: none;
  white-space: pre-wrap;
  display: block;
  font-style: italic;
  /* For Firefox */
  font-size: 15px;
  color: $grey;
}
[contenteditable=true]:focus:before {
  display:none !important;
}


.psuedo-placeholder {
  position:absolute;
  pointer-events: none;
  font-style:italic;
  opacity:0;
  z-index: 999;
  color: #555;
  padding: 10px 15px;
  font-size: 15px;
}


.help-holder {
  display: inline;
}

.Rtable-head {

  h4 i.helpicon,
  h5 i.helpicon {
    margin: -.3rem .1rem 0 .1rem;
  }

}

.helpicon {
  transition: all .3s linear;
  background-color: transparent;
  border-radius: 50% 50% 0 0;
  padding: .1rem .4rem 0 .4rem;
  text-align: center;
  opacity: .5;
  float: right;
  margin-top: -.4rem;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: $icon-info-circle;
    font-size: 1.3rem;
    transform: rotate(0);
    display: block;
  }
}

.RTable-head .helpicon {
  float: right;
}

h1+.help-holder .helpicon,
h1+form .help-holder .helpicon {

  margin-top: .6rem;
}

.help-holder {
  svg {
    width: 38px;
    height: 38px;
    float: right;
    cursor: pointer;
    fill: darken($grey, 25%);
  }
}

.info {
  background-color: #fff;
  //padding:1rem;
  cursor: pointer;
  padding: 0 1.5rem 0 1.5rem;
  max-height: 0;
  font-size: .85rem;
  overflow: hidden;
  font-size: .75rem;
  width: 100%;
  font-weight: normal;
  transition: max-height .3s linear;
  margin-bottom: 15px;

  strong:first-child {
    padding: .8rem 0 .2rem;
    display: block;
    font-size: .85rem;
  }

  p {
    margin: 0;
    padding: 0 0 .2rem;
    line-height: 1.15;
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  ol li,
  ul li {
    margin: 0 0 0 1rem;
    line-height: 1.15;
    padding: 0 0 0.2rem 0;
  }

  a {
    color: unset !important;
  }
}

.info.infohide {
  max-height: 350px;
  padding-bottom: .5rem;
}

.Rtable-group .Rtable-head .info.infohide {
  max-height: 370px;
}

.chart-container {
  position: relative;
  max-width: 820px;
  margin: auto -1%  !important;
  width:102% !important;
  @include media($phablet) {
    margin: auto !important;
    width:100% !important;
  }
}

.chart {
  margin: auto -2%  !important;
  width:104% !important;
  @include media($phablet) {
    margin: auto !important;
    width:100% !important;
  }
}

.chart-buttons {
  overflow: hidden;
  z-index: 1;
  position: relative;
  text-align: center;

  .btn.btn-xs {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: 10px;
    border: 1px solid $grey;
    border-radius: 7.5rem;
    color: $grey;
    max-width: 80px;
    height: 36px;
    padding: 12px 15px 10px;

    &.active {
      background-color: $grey !important;
      border-color: $grey;
    }

    &:hover {
      background: $grey !important;
    }
  }
}





.input-counter {
  position: absolute;
  bottom: .2rem;
  right: .3rem;
  display: none;
  opacity: .6;
  font-size: .75rem;
  z-index: 9999;
  color: #555;

  @include media($sm-tablet) {
    bottom: 0rem;
  }
}


article.article-detail {
  border: 0;

  .about-mysps-body {
    max-width: 950px;
    margin: -2rem auto;
    padding: 5%;
  }

}

form[name="myPlanForm"] {
  position: relative;
  max-width: 100%;

  &.form-loading:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(#fff, 0.125);
  }
}

.tooltip {
  position: relative;

  &__button {
    display: block;
    cursor: pointer;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    padding: 1rem 1.5rem 0.5rem;
    background: #ebebeb;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px rgba(#000, 0.125);
    z-index: 1;
    color: $content-font-color;
    font-size: 12px;
    min-width: 250px;
    transform: translateX(-50%) scale(0.2);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 0;
    visibility: hidden;

    .tooltip--open & {
      transform: translateX(-50%) scale(1);
      opacity: 1;
      visibility: visible;
      cursor: auto;
    }

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: #ebebeb;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      position: absolute;
      top: -10px;
      left: 50%;
      margin-left: -5px;
      transform: rotate(45deg);
      box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
    }
  }

  &__title {
    display: flex;
    font-size: 14px;
    align-items: center;

    svg {
      margin-left: -10px;
      margin-right: 5px;
      width: 38px;
      height: 38px;
    }
  }

  &__close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    fill: #aaa;
    cursor: pointer;
    transition: fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);

    &:hover {
      fill: #333;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.mysps-loading {
	position: relative;
	min-height: 100vh;
}


.home.MySPS .content-container {
  background-image: url('#{$image-path}bg_mysps.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home.MySPS .card-holder .card a {
  padding-top: 0;
  position: relative;
  overflow: hidden;
  box-shadow: none;
  &:before {
    display: block;
    position: absolute;
    content: '';
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -10px;
    left: -10px;
    background-image: url('#{$image-path}bg_mysps.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-color: #fff;
    filter: blur(10px);
  }
}

.task-row {
  @include media($sm-tablet) {
    flex-direction: row-reverse;
    display: flex;
  }
}

.task-col {
  &--list {

    @include media($sm-tablet) {
      padding-right: 25px;
    }

    @include media($tablet) {
      padding-right: 10%;
    }
  }
  &--date {
    padding-bottom: 25px;

    @include media($sm-tablet) {
      padding-bottom: 0;
      flex: 1 0 224px;
      max-width: 224px;
    }
  }
}

%addItem {
  color: $grey;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

  svg {
    height: $icon-size-mobile;
    width: $icon-size-mobile;
    @include media($sm-tablet) {
      height: $icon-size-desktop;
      width: $icon-size-desktop;
    }
    fill: $grey;
    transition: fill 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover {
    color: darken($grey, 10%);
    svg {
      fill: darken($grey, 10%);
    }
  }
}

%deleteItem {
  svg {
    fill: $grey;
    height: $icon-size-mobile;
    width: $icon-size-mobile;
    @include media($sm-tablet) {
      height: $icon-size-desktop;
      width: $icon-size-desktop;
    }
    opacity: 0.5;
    transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.task-list {
  list-style-type: none;
  margin: 0;

  &__item {
    margin: 0;
    padding: 0 0 20px;
    display: flex;

    &--head {
      padding-bottom: 0;
      margin-bottom: 15px;
    }
  }

  &__link {
    color: $main-color;
    font-size: 13px;
    display: flex;
    align-items: center;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    svg {
      fill: $main-color;
      height: 26px;
      width: 24px;
    }
  }

  &__info {
    flex-grow: 1;
    padding-top: 3px;
    padding-right: 20px;
  }

  &__status {
    text-align: center;
    flex: 1 0 100px;
    max-width: 100px;
  }

  &__status-icon {
    display: block;
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #b3b3b3;
    cursor: pointer;

    [class*='--completed'] & {
      background: $main-color;
      box-shadow: inset 0 0 0 4px #fff;
      border: 4px solid $main-color;
    }
  }

  &__status-label {
    font-size: 13px;
    display: block;
    text-align: center;
  }

  &__counter {
    padding-left: 15px;
    padding-right: 30px;
  }

  &__counter-label {
    display: flex;
    border: 2px solid $main-color;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: $main-color;
    width: 30px;
    height: 30px;
    font-weight: bold;
  }

  p {
    margin: 0 0 5px;
  }
}

.task-date {
  &__calendar {
    position: relative;
    padding-bottom: 30px;
    background: #fff;
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
    color: $content-font-color;
    text-align: center;
    font-size: 13px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    font-weight: bold;

    figcaption {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 63px;
      transform: translateX(-50%);
    }
  }

  &__day {
    font-size: 11px;
    line-height: 16px;
  }

  &__date {
    margin-top: 4px;
    margin-bottom: 15px;
    font-size: 58px;
    font-family: 'Panton-Light';
  }

  .svg-icon {
    display: block;
    width: 207px;
    height: 207px;
    margin: auto;
    fill: #2D2D2D !important;
  }
}

.mysps-container {
  button.btn-success:before, input[type="button"].btn-success:before, input[type="reset"].btn-success:before, input[type="submit"].btn-success:before, button.btn-success:before, a.btn-success:before, label.btn-success:before, div.btn-success:before, span.btn-success:before {
    top: 38%;
  }
  .btn-group {
    margin: 0;
  }
  .btn-submit {
    margin: 0;
    background: #fff;
    border: 1px solid $main-color;
    color: $main-color;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 1.25;
    height: 44px;
    min-width: auto;

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.mysps-block-01 {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  background: #fff;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  flex-grow: 1;

  .Rtable-head {
    margin-bottom: 15px;
    @include media($sm-tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Rtable {
    flex-grow: 1;
  }

  .rating {
    margin: 5px auto 0;
    max-width: 500px;
  }
}

.grid {
  &--equal-height {
    > [class*='grid-'] {
      display: flex;
      flex-direction: column;
    }
  }

  &--performance {
    margin-bottom: 50px;

    [class*='--calendar'] {
      margin-top: 50px;
      margin-bottom: 50px;

      @include media($tablet) {
        margin-top: 0;
        margin-bottom: 0;
        flex: 1 0 224px;
        max-width: 224px;
      }
    }
  }
}

.mysps-notes {
  &__item {
    width: 100%;
    padding:2px 0;
    @include media($sm-tablet) {
    padding: 10px;
    }
  }

  @include media($sm-tablet) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &__item {
      flex: 1 1 50%;
      width: 50%;
    }
  }

  &__count{
    //margin-right:30px
    display: inline;
  }
  &__box {
    padding: 8px 15px 10px 5px;
    @include media($sm-tablet) {
      padding: 20px 25px 20px 15px;
    }
    border: 1px solid #EBEBEB;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

    &__head {
      svg {
        margin-top: -2px;
        margin-right: 0px;

            height: $icon-size-mobile;
            width: $icon-size-mobile;
            @include media($sm-tablet) {
              margin-right: 4px;
              margin-top: -9px;
              height: $icon-size-desktop;
              width: $icon-size-desktop;
            }
        fill: $main-color;
      }

      h5 {
        position: relative;
        margin: 0;
        padding: 7px 0 0;
        color: $content-font-color;
        font-size: 18px;
        line-height: 24px;
        @include media($sm-tablet) {
        font-size: 20px;
        line-height: 28px;
        }
        font-weight: 600;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          top: 4px;
          right: -5px;
          background: url("#{$svg-path}Icons/Angle Down.svg") no-repeat center / contain;
          height: 34px;
          width: 34px;
          opacity: 0.325;

          [class$='--open'] & {
            right: 0;
            top: 8px;
            height: 20px;
            width: 20px;
            background-image: url("#{$svg-path}Icons/Angle Up.svg");
          }

          [class$='--empty'] & {
            background-image: url("#{$svg-path}Icons/Add Circle.svg");
            width: 38px;
            height: 38px;
            top: 2px;
            right: -7px;
          }
        }
      }
    }

    &__body {
      padding-top: 15px;
      padding-left: 30px;
      @include media($sm-tablet) {
        padding-left: 40px;
      }
      counter-reset: notesCounter;

      .Rcell-message{margin-bottom:0;}
      .Rtable-group {
        position: relative;
        counter-increment: notesCounter;

      }

      .Rtable-cell {
        display: flex;
        align-items: center;

        [contenteditable] {
          @include media($sm-tablet) {
            margin-right: 20px;
          }
          line-height: 1.4275;
          border: 1px solid transparent;
          flex-grow: 1;
          color: #000;


          &.ng-empty:not(:focus) {
            border-color: lighten($grey, 10%);
          }

          &.ng-shake:not(:focus) {
            border-color: #ff0000;
          }

          &:before {
            content: counter(notesCounter) '.';
            display: block;
            position: absolute;
            z-index: 1;
            font-size: 16px;
            top: 12px;
            left: -20px;
            color: $content-font-color;
            transition: opacity 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
            font-weight: bold;
          }

          &:focus {

            &:before {
              // opacity: 0;
            }
          }
        }

        &__btn {
          margin-top: -10px;
          margin-right: -10px;

          @extend %deleteItem;
        }
      }

      .Rcell-delete {
        right: auto;
        position: relative;
        opacity: 0.25;

        &:hover {
          opacity: 1;
        }

        &:before {
          font-size: 26px;
        }
      }
    }
  }

  &__buttons {
    padding-top: 5px;
    padding-left: 0;
    margin-left:-10px;
    @include media($sm-tablet) {
      margin-left:0;
      padding-left: 5px;
    }
  }

  &__btn {
    @extend %addItem;
  }
}


// Tools
.mysps-tools {
  padding: 40px 58px 60px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

  &__title {
    padding: 0;
    border: 0 !important;
    margin-bottom: 30px !important;
  }

  &__feature {
    @include media($lg-phablet) {
      display: flex
    }

    &--active {
      margin-top: 20px;
      @include media($sm-tablet) {
        display: flex
      }
    }
  }
  &__feature-img {
    flex: 1 0 100px;

    @include media($desktop) {
      flex: 1 0 150px;
    }

    [class*='--active'] & {
      flex: 1 0 250px;

      @include media($lg-phablet) {
        flex: 1 0 450px;
      }
    }

  }
  &__feature-info {
    flex: 1 1 100%;
    max-width: 100%;
    color: $content-font-color;
    font-size: 13px;
    line-height: 18;
    padding-top: 15px;

    @include media($lg-phablet) {
      padding-top: 0;
      padding-left: 35px;
    }

    [class*='--active'] & {
      @include media($sm-tablet) {
        padding-left: 35px;
      }
    }

    h3 {
      padding: 0;
      font-size: 14px;
      line-height: 20px;

      @include media($lg-phablet) {
        padding-top: 5px;
        font-size: 17px;
        line-height: 23px;
      }

      [class*='--active'] & {
        font-size: 17px;
        line-height: 23px;
      }
    }
  }

  &__img-controls {
    display: none;
    padding-top: 5px;
    list-style-type: none;
    text-align: right;

    [class*='--active'] & {
      display: block;
    }

    > li {
      margin-left: 5px;
      display: inline-block;
    }

    svg {
      width: 30px;
      height: 30px;
      fill: $content-font-color;
    }
  }

  &__model {
    padding-top: 20px;
  }
}

.btn-link {
  color: $content-font-color;
  font-size: 13px;
  text-decoration: none;

  &--icon {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      fill: $content-font-color;
      width: 30px;
      height: 30px;
    }
  }

  &--primary {
    color: $main-color;

    svg {
      fill: $main-color;
    }
  }
}

// Self Checks
.mysps-selfchecks {
  &__form {
    margin-bottom: 20px;
    padding: 25px 15px;
    border: 1px solid #EBEBEB;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .svg-toggle {
        height: 55px;
        width: 55px;
        opacity: 0.25;
        cursor: pointer;
      }

      &--open {
        .svg-toggle {
          opacity: 0;
        }
      }
    }

    &__body {
      padding-top: 20px;

      &--has-info {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }
  }

  &__form-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $content-font-color;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    flex-grow: 1;

    @include media($sm-tablet) {
      flex-basis: 50%;
      max-width: 50%;
    }

    &__label {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        height: $icon-size-mobile;
        width: $icon-size-mobile;
        @include media($sm-tablet) {
          height: $icon-size-desktop;
          width: $icon-size-desktop;
        }
        fill: $main-color;
      }
    }

    &__button {
      position: relative;
      @include media($sm-tablet) {
        margin-right: 60px;
      }
    }

    &__button-icon {
      width: 38px;
      height: 38px;
      fill: $content-font-color;
    }

    &__button-count {
      position: absolute;
      display: flex;
      background: $main-color;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      color: #fff;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      line-height: 1;
      top: 0;
      right: 4px;
    }
  }

  &__items {
    width: 100%;
    counter-reset: selfCheckCounter;
    list-style-type: none;
    margin: 0;

    [class*='--has-info'] & {
      flex: 1 1 0;
      max-width: none;

      > li {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include media($sm-tablet) {
      display: flex;
      flex-wrap: wrap;
    }

    > li {
      margin-left: 0;
      margin-bottom: 20px;
      counter-increment: selfCheckCounter;
      position: relative;
      padding: 0 45px;

      @include media($sm-tablet) {
        padding: 0 60px;
        flex: 1 1 50%;
        max-width: 50%;
      }

      &:before {
        content: counter(selfCheckCounter) '.';
        display: block;
        position: absolute;
        top: 0;
        left: 8px;
        color: $content-font-color;
        font-size: 16px;
        line-height: 23px;
        font-weight: 600;

        @include media($sm-tablet) {
          left: 30px;
        }

      }
      [class*='--has-info'] &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }

  &__item {
    position: relative;
    color: $content-font-color;
    font-size: 16px;
    line-height: 23px;

    &__title {
      font-weight: 600;
    }
  }

  &__field {
    margin-top: -12px;
    padding: 12px 15px;
    border: 1px solid transparent;
  }

  &__delete {
    position: absolute;
    top: 0;
    right: -40px;
    cursor: pointer;
    @extend %deleteItem;

    @include media($sm-tablet) {
      right: -50px;
    }

  }

  &__buttons {
    width: 100%;
    padding-left: 50px;

    @include media($sm-tablet) {
      padding-left: 75px;
      &--pullright {
        padding-left: calc(50% + 75px);
      }

      [class*='--has-info'] & {
        padding-left: 10px;
      }
    }
  }

  &__btn {
    @extend %addItem;
  }

  &__tooltip {
    .tooltip {
      &__content {
        margin-left: -6px;
        padding-top: 25px;
      }

      &__title {
        font-size: 17px;
      }

      &__close {
        top: 5px;
        right: 5px;
      }

    }
    &.tooltip--open {
      .tooltip__content {
        @include media($sm-tablet-max) {
          transform: translateX(-89%) scale(1);
          &:before {
            left: auto;
            right: 6.5%;
          }
        }
      }
    }
  }

  &__info {
    display: block;
    padding-left: 20px;
    padding-right: 20px;

    @include media($sm-tablet) {
      padding-right: 5%;
      flex: 1 1 50%;
      max-width: 50%;
    }

    > svg {
      display: none;

    }
    .info {
      padding: 10px 30px 20px;
      max-height: none;
      border: 1px solid #D8D8D8;
      background-color: #EBEBEB;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
      color: $content-font-color;
      font-size: 13px;
      cursor: auto;

      > strong {
        font-size: 17px;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          display: block;
          width: 38px;
          height: 38px;
          background: url("#{$svg-path}Icons/Info.svg") no-repeat center / contain;
          opacity: 0.875;
          margin-right: 10px;
          margin-left: -2px;
        }
      }

      > div {
        margin-top: 20px;
        p {
          display: flex;
          margin-bottom: 10px;
          line-height: 18px;
          > span {
            flex: 1 0 60px;
            max-width: 60px;
            margin-right: 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.tooltip-form {
  font-size: 12px;
  @include media($sm-tablet) {
    font-size: 17px;
  }

  &__row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
  }

  &__col {
    padding-left: 10px;
    padding-right: 10px;
    flex: 1 1 100%;
    max-width: 100%;

    &--days,
    &--ampm {
      flex: 1 1 40%;
      max-width: 40%;
    }

    &--time {
      flex: 1 1 60%;
      max-width: 60%;
    }

    &--auto {
      flex: 0 1 auto;
      max-width: auto;
    }

    &--ampm {
      @include media($sm-tablet-max) {
        flex: 1 0 80px;
        max-width: 80px;
        [class*='__radioboxes'] {
          > li {
            padding-left: 0;
          }
        }
      }
    }
  }
  &__button{
    height:24px;
    overflow: hidden;
  }

  &__label {
    display: block;
    margin-bottom: 15px;
    color: $grey;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 18px;
    text-transform: uppercase;

    @include media($sm-tablet-max) {
      margin-bottom: 10px;
    }
  }

  &__checkboxes,
  &__radioboxes,
  &__buttons {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      padding: 0 0 5px;
      margin: 0;

      @include media($sm-tablet-max) {
        padding-bottom: 0;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  &__select {
    &__field {
      margin-left: -5px;
      margin-right: -5px;
      position: relative;

      &:after {
        content: '';
        height: 100%;
        width: 14px;
        position: absolute;
        top: 0;
        right: 0;
        background: url("#{$svg-path}Icons/Angle Up.svg") no-repeat 0 3px / 14px,
        url("#{$svg-path}Icons/Angle Down.svg") no-repeat 0 12px / 14px;
        filter: invert(63%) sepia(0%) saturate(0%) hue-rotate(147deg) brightness(92%) contrast(93%);
        z-index: -1;

        @include media($sm-tablet-max) {
          top: -2px;
          background-size: 10px;
        }
      }

      select {
        display: block;
        width: 100%;
        padding: 5px 20px 5px 5px;
        margin: 0;
        background: none;
        appearance: none;
        border: none;
        border-radius: 0;
        font-family: $reg-font;
        font-weight: bold;

        &::-ms-expand {
          display: none;
        }
      }
    }

    &--time {
      display: flex;
      margin-right: -10px;
      margin-left: -10px;

      > [class*='__field'] {
        padding-left: 10px;
        padding-right: 10px;
        flex: 1 1 100%;

        &:not(:last-child) {
          &:before {
            content: ':';
            position: absolute;
            right: 0;
            top: 0;

            @include media($sm-tablet-max) {
              top: -4px;
              right: 2px;
              font-size: 16px;
            }
          }

          &:after {
            right: 10px;
          }
        }
      }
    }
  }

  &__radioboxes {
    &--vertical {
      display: flex;

      @include media($sm-tablet) {
        margin-left: 10px;
      }

      [class*='__radiobox'] {
        padding-left: 10px;
        label {
          flex-direction: column-reverse;
          align-items: center;
        }

        svg {
          margin: 3px 0 0;
        }
      }
    }
  }

  &__radiobox {
    cursor: pointer;

    svg {
      margin-top: -4px;
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }

    label {
      display: flex;
      align-items: flex-start;
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;

    @include media($sm-tablet-max) {
      margin-top: 30px;
    }

    > li {
      padding-left: 5px;
      padding-right: 5px;
      flex: 1 1 100%;
      max-width: 100%;
    }

    .btn {
      margin: 0;
      width: 100%;
      height: 55px;
      font-size: 11px;
      letter-spacing: 2px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-alt {
      border: 1px solid $grey;
      color: $grey;
      background: none;

      &:hover:not(:disabled) {
        border-color: darken($grey, 15%);
        color: darken($grey, 15%);
        background: none;
      }
    }
    .btn-submit {
      width: 100%;
      background: none;
    }
  }

  &__text {
    &__field {
      position: relative;

      #{$text-inputs-list} {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        font: inherit;;
      }
    }
  }
}

.ng-shake {
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.MySPS .article-header h1 {
  padding-left: 25px;
  font-size: 36px;
  @include media($sm-tablet-max) {
    font-size: 26px;
    padding-left: 10px;
  }
}
.MySPS .article-header .articles-main {
  justify-content: flex-start;
  .channel-display {
    @include media($sm-tablet-max) {
      padding-left: 0;
      padding-right: 10px;
      .channel-number {
        font-size: 26px;
      }
    }
  }
}
.MySPS .article-header .channel-number {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.1;
}
.MySPS.articles-page h2.title--back {
  display: flex;
  align-items: center;
  > a {
    margin-left: auto;
    font-weight: normal;
    font-size: 14px;
    @include media($sm-tablet) {
    font-size: 17px;
    }
  }
}

.Rtable-cell--title {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #eee;


  .tooltip__button {
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

@include media($sm-tablet) {
  .Rtable-cell--title {
    display: none;
  }
}
