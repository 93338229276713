/*Paths*/

$font-path: "../assets/fonts/";
$image-path: "../assets/images/";
$svg-path: "../assets/svg/";
/*theme palete*/

$black:#38444B;
$grey:#B1B1B1;
$dark-grey:#748191;
$red:#ED1E23;
$green:#3eb44a;
$orange:#ffc048;
$purple:#7448FF;
$blue:#00ADEF;
$dark-blue: #2D363C;
$dark-dark-blue: #22292D;
/*theme color variables*/

$main-color:$blue;
$sec-color:$dark-blue;
$third-color:$dark-dark-blue;
$sale-color:$red;
/*alert colors*/

$success-color:$green;
$error-color:$red;
$danger-color:$red;
$info-color: $sec-color;
$warning-color: $orange;
$nav-icon-color:rgba(255, 255, 255, .5);
$background: #f4f5f7;
/*fonts*/

$bold-font:"proxima-nova", Arial, Helvetica, sans-serif;
$bold-font-weight:700;

$semi-font:"proxima-nova",Arial,Helvetica,sans-serif;

$reg-font:"proxima-nova",Arial,Helvetica,sans-serif;
$reg-font-weight: 300;

$light-font:"Panton-Light", Arial, Helvetica, sans-serif;

$title-font:$bold-font;
$title-font-weight: $bold-font-weight;

$nav-font:$reg-font;

$content-font: $reg-font;
$content-font-weight: 300;
$content-font-color: #262626;
$content-font-size:16px;
$content-line-height:1.3;
/*spacing*/

$form-inline-form-fields:false;
$form-field-label-color:$black;
$form-field-label-size:17px;
$form-field-label-font:$content-font;
$form-field-border-color:$sec-color;
$form-field-border:2px solid $form-field-border-color;
$form-field-color:$dark-dark-blue;
$form-field-color-disabled:#ccc;
$form-field-radius: 0;
$form-field-font: $content-font;
$form-field-background-color: #f9f9f9;
$form-field-hover-border-color:#aaa;
$form-field-focus-border-color:#222;
$form-row-spacing:1em;
/*buttons*/

$container-maxwidth:1166px;
$button-radius:0;
$continer-padding-desktop:60px;
$sm-mobile-value: 330px;
$mobile-value: 350px;
$lg-mobile-value: 370px;
$sm-phablet-value:400px;
$phablet-value:450px;
$lg-phablet-value: 550px;
$sm-tablet-value: 767px;
$tablet-value: 1000px;
$lg-tablet-value: 1100px;
$desktop-value: 1250px;
$lg-desktop-value: 1550px;

$sm-mobile-max: screen and (max-width: #{$sm-mobile-value - 1px});
$sm-mobile: screen and (min-width: #{$sm-mobile-value});
$mobile-max: screen and (max-width: #{$mobile-value - 1px});
$mobile: screen and (min-width: #{$mobile-value});
$lg-mobile: screen and (min-width: #{$lg-mobile-value});
$lg-mobile-max: screen and (max-width: #{$lg-mobile-value - 1px});
$sm-phablet-max: screen and (max-width: #{$sm-phablet-value - 1px});
$sm-phablet: screen and (min-width: #{$sm-phablet-value});
$phablet-max: screen and (max-width: #{$phablet-value - 1px});
$phablet: screen and (min-width: #{$phablet-value});
$lg-phablet: screen and (min-width: #{$lg-phablet-value});
$lg-phablet-max: screen and (max-width: #{$lg-phablet-value - 1px});
$sm-tablet: screen and (min-width: #{$sm-tablet-value});
$sm-tablet-max: screen and (max-width: #{$sm-tablet-value - 1px});
$tablet: screen and (min-width: #{$tablet-value});
$tablet-max: screen and (max-width: #{$tablet-value - 1px});
$lg-tablet: screen and (min-width: #{$lg-tablet-value});
$lg-tablet-max: screen and (max-width: #{$lg-tablet-value - 1px});
$desktop: screen and (min-width: #{$desktop-value});
$desktop-max: screen and (max-width: #{$desktop-value - 1px});
$lg-desktop: screen and (min-width: #{$lg-desktop-value});
$lg-desktop-max: screen and (max-width: #{$lg-desktop-value - 1px});
$max-width: screen and (min-width: #{$container-maxwidth});

.mobile-hide {
  @media screen and (max-width: #{$mobile-value}) {
    display: none;
  }
}

.desktop-hide {
  @media screen and (min-width: #{$desktop-value}) {
    display: none;
  }
}

$mobile-menu-break: $lg-tablet;
$mobile-menu-break-max: $lg-tablet-max;

$small-spacing: .5rem;
$large-spacing:1rem;
$max-width:100%;
$header-height-desktop:60px;
$header-height-mobile:60px;
$grid-gutter: 5%;
$grid-vert-margin:0;
$grid-vert-padding:0;
$grid-breakpoint:$lg-phablet;

$sidebar-width: 340px;
/*form fields: textarea, checkbox, selects etc*/



$dropmenu-bg: $dark-blue;
$dropmenu-bg-hover: $main-color;
$sidebar-bg:$dropmenu-bg;
$sidebar-bg-hover:$dropmenu-bg-hover;


$article-header-height:6.8rem;


%iconMenuSize {
  width: 32px !important;
  height: 32px !important;
}