.st-tab-content {
	.tab-pane:not(.active) {
		display: none;
	}
}
.st-tabs {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;

	li {
		margin: 0;
		padding: 0;
		font-size: 13px;
		font-weight: 600;
		letter-spacing: 0.25px;
		line-height: 19.5px;
		text-align: center;
		
		> a { color: #AAB3BE; }
		&.active {
			a {
				position: relative;
				display: block;
				color: #38444B;

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 1.25px;
					position: absolute;
					bottom: -5px;
					left: 0;
					background: #38444B;
				}
			}
		}

		+ li {
			margin-left: 20px;
		}
	}
}