.col-filter {
  display: flex;

}

filter-column {
  .dropmenu-trigger__btn {
    margin: -1rem 0 -1rem 0.5rem;
    
    svg {
      width: 18px !important;
      height: 18px !important;

      &.active {
        fill: $main-color;
      }
    }
  }

  .dropmenu-holder {
    background: #ebebeb;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px rgba(39, 26, 26, 0.125);
    top: calc(100% + 1rem) !important;
    right: -1rem;
    ul {
      max-height: 260px;
      overflow: hidden auto;
      li {
        &:not(:first-child) {
          border-top: 1px solid #ddd;
        }
        a {
          display: flex !important;
          padding: 0.5rem;
          background: transparent;
          font-size: 13px;
          color: unset;
          min-height: unset;
          font-weight: normal;
          
          &.link--disabled {
            pointer-events: none;
          }
          
          &.link--disabled {
            background: $grey;
            color: darken($color: $grey, $amount: 30)
          }

          > .checkbox {
            margin: 0 0 0 auto;
            top: -8px;
            left: 20px;
          }

          &:hover,
          &.link--active {
            color: $white;
            font-weight: 600;

            > .checkbox {
              &:before,
              &:after {
                filter: invert(94%) sepia(77%) saturate(3933%) hue-rotate(187deg) brightness(159%) contrast(100%);
              }
            }
          }
        }
        
        &[disabled] {
          a {
            background: #aaa !important;
          }
        }
      }
    }

    &:before {
      content: '';
      border-color: transparent;
      display: block;
      width: 20px;
      height: 20px;
      background: #ebebeb;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      transform: rotate(45deg);
      box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
      top: -0.5rem;
    }

    &--first-over {
      &:before {
        background: $main-color;
      }
    }
  }
}