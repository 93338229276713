

    @mixin dropdown($holderClass:'.dropdown-holder', $dropdown-icon: '\f101') {
      position: relative;
      z-index: 99;
      &:hover {
        background: #fff;
      }
      &>:first-child:after {
        @include font-awesome($dropdown-icon);
        display: inline-block;
        font-size: 9px;
        padding: 0 0 .2rem .3rem;
        color: #999;
        height: 100%;
        vertical-align: middle;
      }
      #{$holderClass}{
        position: absolute;
        width:100%;
        opacity:0;
        visibility: hidden;
        margin-top:-15px;
        left:0;
        border:3px solid #ececec;
        background-color:#fff;
        z-index: -1;
        border-radius:0 0 5px 5px;
        transition:all .2s linear;
        padding:1rem 1.2rem;

      }
      &:hover #{$holderClass} {
        opacity: 1;
        visibility: visible;
        margin-top: 0;
      }

  }



	@mixin font-awesome($icon: "\f101") {
		content:$icon;
		font-family: 'FontAwesome';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		text-decoration: inherit;
		vertical-align: middle;

		/* Better Font Rendering =========== */
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
  }

  
  
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
  &:placeholder                {@content}  
}

	[class^="icon-"], [class*=" icon-"] {
      display: inline-block;
      @include icon;
  }

  @mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color 
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  
    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-#{($direction)}: $size solid $color;
    
    $perpendicular-borders: $size solid transparent;
    
    @if $direction == top or $direction == bottom {
      border-left:   $perpendicular-borders;
      border-right:  $perpendicular-borders;
    } @else if $direction == right or $direction == left {
      border-bottom: $perpendicular-borders;
      border-top:    $perpendicular-borders;
    }
  }



  @mixin sub-sup{
      /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
      font-size:70%;
      /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
      line-height: 0;
      /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
      position: relative;
      /* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
     vertical-align: baseline;
  }

  @mixin sup{
        @include sub-sup;
        /* Move the superscripted text up */
        top: -0.25rem;
  }

  @mixin sub{
    @include sub-sup;
    /* Move the subscripted text down, but only
      half as far down as the superscript moved up */
    bottom: -0.25rem;
  }
