.popupOpen {
  overflow-y: auto;

  app-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.5);
    z-index: 9999;
  }
}
.popupHolder {
  display: flex;
  flex-direction: column;
  background-color:#fff;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  box-shadow: 0 0 10px #000;
  transition: all .2s linear;
  transform: scale(.1, .1);
  flex-wrap: nowrap;

  @include media($tablet) {
    &:not(.fullscreen) {
      top: 80px;
      left: 50%;
      max-width:1400px;
      transform:translateX(-50%);
      width: calc(100% - 160px);
      height: calc(100% - 160px);
    }
  }

  // .fullscreen-btn { display: none; }
  .canvas-container {
    margin: auto;
    overflow: hidden;
  }

  &.fullscreen.android,
  &.pdf-viewer {
    .canvas-footer__copyright {
      display: none;
    }
    .canvas-footer__controls {
      > li:not(:last-child) {
        display: none;
      }
      
      > li:last-child {
        button {
          padding: 0;
          height: 32px;
        }
      }
      
      // + .external-btnClose {
      //   display: block;
      //   position: fixed;
      //   top: 0;
      //   right: 0;
      // }
    }
    .canvas-footer {
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 0;
    }
  }
  .canvas-footer {
    &.is-touch {
      .canvas-footer__controls {
        .zoom {
          // display: none;
        }
      }

      &.android {
        // .fullscreen-btn { display: initial; }
      }
    }
  }
  &:before {
    cursor: pointer;
    @include icon($icon-cross);
    display: inline-block;
    padding: .95rem;
    @include media($mobile) {
      padding: 1.25rem;
    }
    text-shadow: 0 0 1px #fff;
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    display: none;
  }
  &:hover:before {
    color: #333;
  }

  &.html-content {
    .canvas-body {
      overflow: auto;
    }
  }
}
.popupOpen .popupHolder{
  visibility: visible;
  opacity: 1;
  z-index: 99999;
  transform: scale(1, 1);
  @include media($tablet) {
    &:not(.fullscreen) {
      transform: scale(1, 1) translateX(-50%);
    }
  }
}

.zoom-image{
  &:hover {
    opacity:.9;
  }
}
.canvas-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f1f1f1;

  @media print { display: none; }

  .html-content & {
    .canvas-footer__controls {
      li:not(:last-child) {
        display: none !important;
      }
    }
  }


  @media only screen and (max-width: 766px) {
    flex-wrap: wrap;

    > * {
      width: 100%;
    }

  }
}
.canvas-footer__copyright {
  @media only screen and (min-width: 767px) {
    padding-left: 1.5rem;
  }

  @media only screen and (max-width: 766px) {
    text-align: center;
    padding: 5px;
    border-top: 1px solid #ddd;
    font-size: 0.75rem;
  }
}
.canvas-footer__controls {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: auto;

  @media only screen and (max-width: 766px) {
    justify-content: center;
    order: -1;

    .fullscreen-btn {
      display: none;
    }
  }

  li {
    margin: 0;
    padding: inherit;
    button {
      padding: 0.75rem;
      border: 0;
      background: none;
      outline: none;
      cursor: pointer;
      @include media($tablet) {
        padding: 1.1rem;
      }
      &:hover {
        background: $main-color;
        .svg-icon {
          fill: #fff !important;
        }
      }
      .svg-icon {
        width: 2rem;
        height: 2rem;
        @media only screen and (max-width: 766px) {
          width: 1.5rem;
          height: 1.5rem;
        }
        fill: $content-font-color !important;
      }
    }
    &.zoom:not(.active) {
      display: none;
    }
    &:last-child {
      position: absolute;
      top: 0;
      right: 0;
      background: #f1f1f1;
      z-index: 1;
    }

    &.zoom {
      @include media($lg-tablet-max) {
        // display: none;
      }
    }
  }
}
.iframe-popup-button {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 42px;
  background: rgb(24, 24, 24);
  cursor: pointer;

  &:hover:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.2);
    border-bottom: 2px solid rgb(238, 238, 238);
    z-index: 0;
  }

  svg {
    position: relative;
    width: 36px;
    height: 36px;
    z-index: 1;
    fill: #fff;
    pointer-events: none;
  }
}
.canvas-body {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 0;

  .image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    @media print { display: none; }

    &--show {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      visibility: visible;

      & ~ .canvas-container,
      & ~ canvas,
      & ~ iframe,
      & ~ embed {
        display: none;
      }

      > img {
        opacity: 0;
        pointer-events: none;
      }
    }

    .image-boundary,
    img {
      max-width: 100%;
      max-height: 100%;

      &[src*=".svg"] {
        width: 100%;
        height: 100%;
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        touch-action: none;

        &.reset {
          transition: -webkit-transform 0.3s ease-in-out;
          transition: transform 0.3s ease-in-out;
        }
      }
    }

  }
  .popup-iframe-block {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    iframe,
    embed {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    &:not(.popup--loaded):before {
      content: 'Loading...';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      font-size: 1.75em;

      @media print { display: none; }
    }
    
    &[data-src*="/404"] {
      &:before {
        content: 'Not Found';
      }
    }
  }
}
.bg-absolute-image--svg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -99999;
  opacity: 0;
  pointer-events: none;
  max-width: 500px;
}
.fullscreen-note {
  padding: 1rem;
  position: absolute;
  display: block;
  background: #f1f1f1;

  .btn-style {
    display: inline-block;
    padding: 5px 8px;
    border: 1px solid $main-color;
    font-family: $bold-font;
  }
}
.external-btnClose {
  display: none;
  background: $main-color;
  border: 0;
  outline: none;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
    fill: #fff !important;
  }
}
.pdfjs .pdfViewer {
  .page {
    margin-bottom: 10px;
    border-image: unset;   
  }

  .canvasWrapper {
    box-shadow: 0 0 5px rgba(#000, 0.5);
  }
}
