@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
  &>li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@mixin link-reset {
  text-decoration: none;
  display: block;
}

@mixin p-reset {
  margin: 0;
  line-height: 1;
  padding: 0;
}

@mixin button-reset {
  border: none;
  border-radius: 0;
  background: none;
  text-decoration: none;
  &:focus {
    outline: 0;
  }
  line-height: normal;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
@mixin input-reset {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; //white-space: nowrap;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    line-height: normal;
    &:focus {
      outline: none;
    }
}

