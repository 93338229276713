$flyoutTransition: all 0.5s ease-in-out;
.flyout {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 1000;
  transition: #{$flyoutTransition} 0.5s;

  &__overlay {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    transition: $flyoutTransition;
    opacity: 0;
    z-index: -1;
    visibility: hidden;

    .flyout--open & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__block {
    position: relative;
    height: 100%;
    z-index: 1;
    background: $dark-blue;
    @include media($mobile-menu-break) {
      border-left: 1px solid $sidebar-border-color;
    }
  }

  &__content {
    overflow: auto;
    height: 100%;
  }

  @include media($mobile-menu-break) {
    &__content {
      padding: 2.5rem 4rem 5rem;
    }
    .site-logo {
      display: none;
    }
    left: -($sidebar-width * 2);

    body.home &, body.support & {
      left: auto;
      right: -($sidebar-width * 2);
    }

    &--open {
      left: $sidebar-width;

      body.home &,body.support & {
        left: unset;
        right: 0;
      }
    }
  }

  @include media($mobile-menu-break-max) {
    width: 100%;
    right: -100%;
    &__content {
      padding: 7.5rem 2.5rem 5rem;
    }

    @include media($phablet) {
      max-width: 480px;
    }

    &--open {
      right: 0;
    }

    .site-logo {
      display: block;
      position: absolute;
      top: 1.125rem;
      right: 2.25rem;
      width: 1.5rem;
    }
  }

  &__group-field {
    display: flex;
    width: 100%;
    @include media($mobile-menu-break) {
      width: 325px;
    }

    svg {
      width: 38px;
      height: 38px;
      fill: $grey;

      .field-invalid & {
        fill: $red;
      }

      .modal-content &,
      .form-field.form-field--light & {
        fill: $grey;
      }
    }

    &-note {
      max-width: 270px;
      color: #FFFFFF;
      opacity: 0.3;
      font-size: 13px;
      letter-spacing: 0.25px;
      line-height: 18px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      &--fluid {
        max-width: unset;
        text-align: left;
      }
    }

    .find-user-form & {
      max-width: 350px;
      width: 100%;
    }
  }

  &__field {
    &-input {
      position: relative;
      flex-grow: 1;

      &--has-icon {
        #{$text-inputs-list} {
          padding-left: 28px !important;
        }
      }

      &-icon {
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -19px;

        .form-field.form-field--xs & {
          left: 5px;
          margin-top: -12px;
        }
      }

      #{$text-inputs-list} {
        margin: 0;
        padding-left: 60px;
        padding-right: 50px;
        background: #303f4c;
        color: #fff;
        border-color: transparent;

        &:focus {
          border-color: transparent;
        }

        &:hover {
          border-color: #999;
        }

        &::placeholder {
          color: #aaa;
        }

        .modal-content &,
        .form-field.form-field--light & {
          padding-top: 16px;
          padding-bottom: 16px;
          background: #fff;
          color: $dark-grey;
          border: 1px solid #fff;

          &:hover {
            border-color: transparent;
          }
        }

        .form-field.form-field--xs & {
          padding: 8.25px 3em 8.25px 28px;
          font-size: 14px;
        }
      }

      &-model {
        &--no-icon {
          padding-left: 20px !important;
        }

        &--busy {
          pointer-events: none;
        }
      }

      &-clear {
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -19px;
        cursor: pointer;

        .flyout__group-field--search & {
          &:before {
            display: none;
          }
        }

        .field-invalid & {
          &-icon {
            fill: $red;
          }
        }

        .form-field.form-field--xs & {
          right: 7px;
          margin-top: -12px;
        }
      }

      .select-holder {
        margin: 0;
        background: #303f4c;
        color: #fff;

        .form-field.form-field--light & {
          background: #fff;
          color: $dark-grey;
          border: 1px solid #fff;
        }

        select {
          color: inherit;
        }

        .modal-content & {
          background: #fff;
          color: $dark-grey;
          border: 1px solid #fff;
        }
      }

      > .loading {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
        border-color: $main-color rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2);
      }
    }

    &-dropdown {
      background: #243542;
      border: 2px solid #243542;
      padding: 0 15px;
      &-icon {
        width: 26px !important;
        height: 26px !important;
      }

      .modal-content &,
      .form-field.form-field--light & {
        background: $main-color;
        border-color: $main-color;
        padding: 0 10px;
        cursor: pointer;

        &-icon {
          width: 36px !important;
          height: 36px !important;
          fill: #fff !important;
        }
      }

      .form-field.form-field--xs & {
        padding: 0 5px;
        &-icon {
          width: 28px !important;
          height: 28px !important;
        }
      }
    }
  }

  &__suggestion {
    margin-top: 2.5rem;
    max-width: 325px;
    h4 {
      margin-bottom: 1.25rem;
      color: #fff;
      font-size: 13px;
      font-weight: 700;
      font-family: $reg-font;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $sidebar-border-color;

      li {
        margin: inherit;
        padding: 0;
        border-top: 1px solid $sidebar-border-color;

        a {
          display: block;
          padding: 1rem;
          color: #fff;
          font-size: 14.5px;

          .highlight-text {
            font-weight: bold;
          }

          &:hover {
            background-color: $main-color;
          }
        }
      }
    }

    &-blurb {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;

    body.home &, body.support & {
      left: 2.25rem;
      right: auto;
      transform-origin: center;
      transform: rotate(180deg);
    }

    @include media($mobile-menu-break) {
      bottom: 1.75rem;
      right: 2.25rem;
    }

    @include media($mobile-menu-break-max) {
      top: 1.125rem;
      left: 2.25rem;
    }

    &-icon {
      @include iconSize();
      fill: #98A9BC;

      &--arrow {
        display: none;
        @include media($mobile-menu-break) {
          display: block;
        }
      }
      @include media($mobile-menu-break) {
        &--close {
          display: none;
        }
      }
    }
  }
}

button[class*='--no-style'] {
  background: none;
  border: none;
  outline: none;
}

.flyout {
  &__account {
    margin: 0 auto 50px;
    @include media($mobile-menu-break) {
      min-width: 325px;
    }
  }

  &__fielset {
    max-width: 325px;
    margin-top: 20px;
  }

  &__field {
    position: relative;
    margin-top: 15px;
    &-label {
      color: #ffffff;
      display: block;
      font-size: 13px;
      line-height: 18px;
      margin-bottom:5px;

      .modal-content &,
      .form-field.form-field--light & {
        color: $dark-grey;
      }
    }
  }
}

.account {
  &__buttons {
    margin-top: 60px;
    position: relative;
    text-align: center;
    z-index: 1;

    > .btn-account {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }

    &--main {
      > .btn-account {
        min-width: 200px;
      }
    }
  }
}

.account-info {
  margin-top: 40px;
  &__field {
    display: flex;
    padding: 15px 0;
    align-items: center;
  }

  &__icon {
    margin-right: 10px;
    height: 38px;
    width: 38px;
    fill: rgba(255, 255, 255, 0.5);
  }

  &__value {
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
  }
}

$image-size-lg: 120px;
$image-size-md: 80px;

.account-photo {
  &__block {
    position: relative;
    max-width: $image-size-md;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($mobile-menu-break) {
      max-width: $image-size-lg;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $dark-blue;
      border-radius: 50%;
      z-index: 1;

      body.register & {
        background: $bg_register;
      }
    }

    .svg-photo {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 140px;
      height: 140px;
      fill: rgba(255, 255, 255, 0.5);
      transform: translate(-50%, -50%);
      z-index: 2;
      pointer-events: none;

      @include media($mobile-menu-break) {
        width: 210px;
        height: 210px;
      }
    }
  }

  &__edit {
    position: absolute;
    top: 0;
    left: -90%;
    display: flex;
    align-content: center;
    justify-content: center;
    width: $image-size-md;
    height: $image-size-md;
    background-color: rgba(#ffffff, 0.125);
    border-radius: 50%;
    cursor: pointer;
    z-index: 0;

    @include media($mobile-menu-break) {
      width: $image-size-lg;
      height: $image-size-lg;
    }

    &__icon {
      align-self: center;
      width: 38px;
      height: 38px;
      fill: rgba(255, 255, 255, 0.325);
    }
  }

  &__active {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    max-width: $image-size-md;
    max-height: $image-size-md;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include media($mobile-menu-break) {
      max-width: $image-size-lg;
      max-height: $image-size-lg;
    }
  }

  &__update {
    position: relative;
    opacity: 0;
    visibility: hidden;
    z-index: 3;

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.btn-account {
  padding: 15px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  color: $main-color;
  height: 55px;
  border: 1px solid $main-color;
  min-width: 90px;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  transition: $flyoutTransition;

  &:hover {
    color: #fff;
    background: $main-color;
  }

  &--alt {
    color: #fff;
    border-color: #fff;
    opacity: 0.5;

    &:hover {
      color: #fff;
      border-color: #fff;
      background: none;
      opacity: 1;
    }
  }
}


.image-crop {
  width: $image-size-md;
  height: $image-size-md;

  @include media($mobile-menu-break) {
    width: $image-size-lg;
    height: $image-size-lg;
  }

  img {
    border-radius: 0 !important;
  }
}

.form-field--xs {
  svg {
    width: 24px;
    height: 24px;
  }
}
.sidebar-pusher--minimal ~ .flyout-ui {
  .flyout--open {
    left: 75px;
  }
}